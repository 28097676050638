import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
    tr_texts: window.tr_texts || {},
    _(string) {
        return this.tr_texts[string] || string;
    },
    // masks: ['+# (###) ###-##-##', '+## (###) ###-##-##'],
    // phone_placeholder: '',
    local: window.navigator.userLanguage || window.navigator.language,
    masks: (window.masks && window.masks.length && window.masks[0] ? window.masks : ['+7 (###) ###-##-##', '+## (###) ###-##-##']).map(m => m.split('9').join('#')),
    phone_placeholder: (window.masks && window.masks.length && window.masks[0] ? window.masks : ['+7 (___) ___-__-__'])[0].split('9').join('_'),
    checkPhone(phone) {
        if (phone[1] == 8) phone = phone[0] + '7' + phone.substr(2);
        if (phone[1] == 7 && phone[4] == 8) {
            phone = phone.substr(0, 4);
        }
        return phone;
    },
    localhost: window.location.host.includes('localhost:'),
    setMask(phone) {
        if (this.masks.length === 1) return this.masks[0];
        let digits = phone.replace(/[^0-9]/g, "");
        if (+digits[0] === 9) return this.masks[1];
        let masks = this.masks;
        for (let i in masks) if (masks[i].split('#').length - 1 === digits.length) return masks[i];
        return masks[0];
    }
}

const mutations = {}

export default new Vuex.Store({
    state,
    mutations,
})