import Vue from 'vue'
import App from './App.vue'
// import Vuetify from 'vuetify'
import '@mdi/font/css/materialdesignicons.css'
// import VueTheMask from 'vue-the-mask'
import VueTheMask from 'v-mask'
import store from './store'

Vue.use(VueTheMask)
import Vuetify from 'vuetify'
Vue.use(Vuetify)
Vue.config.productionTip = false
Vue.prototype.$eventBus = new Vue();

new Vue({
  render: h => h(App),
  vuetify: new Vuetify(),
  icons: {
    iconfont: 'mdi',
  },
  store,
  methods: {}

}).$mount('#app')
