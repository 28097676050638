<template>
  <div class="service-container service-container-v2">
    <div class="service service-v2 py-2 px-5 px-sm-7 flex-nowrap ma-0"
         :class="{
      'dry-service': is_dry_service || is_dry_service_item || is_leather,
            'selected-service': (+options[service.form_name] > 0) || selectedSectionCount() || dry_services_count || is_leather,
           'no-bottom-border': is_dry_service_item || is_leather}">
<!--      || dry_services_count ||
            (is_dry_service && service.key && selected_dry_service === service.key) || is_dry_service_item-->
      <div class="ma-0 pa-0">
            <span class="service__name">{{ service.name || service.title }}
              <span style="color: #B5B0AC" v-if="selectedSectionCount()">
                {{ `(${selectedSectionCount()})` }}
              </span>
              <span style="color: #B5B0AC" v-else-if="dry_services_count">
                {{ `(${dry_services_count})` }}
              </span>
            </span>
      </div>
      <div class="service__price">{{ is_dry_service ? dryPriceStringBuilder : priceStringBuilder }} </div>
      <div class="d-flex buttons-info-container">
        <div class="d-flex pa-0 ma-0" style="width: 100%">
          <v-btn v-if="is_leather"
                 @click="options[service.form_name] = +options[service.form_name] ? 0 : 1;
                                $forceUpdate();
                             $emit('on-change-value')"
                 class="btn-plus pa-0"
                 :style="{'background-color':options[service.form_name]?'#333333':'transparent',
                                  'border': `1px solid ${options[service.form_name]?'#333333':'#B5B0AC'}`,
                        }">
            <v-icon :color="'#fff'" v-if="+options[service.form_name]">mdi-check</v-icon>
          </v-btn>
          <div v-else-if="service.form_type === 'text'" class="service-value-stepper">
            <v-btn class="btn-minus v-btn-gray"
                   :style="options[service.form_name] > 0 ? 'background-color: #9BF0F5 !important' : ''"
                   :disabled="!options[service.form_name] || options[service.form_name] === (parseInt(service.form_value) || 0)"
                   @click="minusval(service.form_name)">-
            </v-btn>
            <div class="value">
              <v-text-field
                  autocomplete="off"
                  type="text"
                  :maxlength="2"
                  class="value-text-field"
                  v-model="options[service.form_name]"
                  @change="options[service.form_name]*1 < service.form_value*1 ? options[service.form_name] = service.form_value : ''; $emit('on-change-value')"
              />
            </div>
            <v-btn class="btn-plus v-btn-gray"
                   :style="options[service.form_name] > 0 ? 'background-color: #9BF0F5 !important' : ''"
                   @click="plusval(service.form_name)">+
            </v-btn>
          </div>
          <template v-else>
            <v-btn
                v-if="is_dry_service && service.key ? selected_dry_service !== service.key : service.form_type === 'checkbox' && !+options[service.form_name]"
                @click="onAddServiceClick"
                class="btn-add">
              {{ $store.state._(selectedSectionCount() || dry_services_count ? 'Изменить' : `Добавить`) }}
            </v-btn>
            <v-btn
                v-else-if="is_dry_service && service.key ? selected_dry_service === service.key : service.form_type === 'checkbox' && +options[service.form_name]"
                @click="onRemoveServiceClick"
                class="btn-remove">{{ $store.state._(is_dry_service ? 'Скрыть' : `Отменить`) }}
            </v-btn>
          </template>
        </div>
        <div v-if="service.form_name !== 'him'" class="service__tip pa-0 ma-0">
          <v-tooltip bottom open-on-click :open-on-hover="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-if="service.info" class="ml-4" v-bind="attrs" v-on="on" color="#d6d0cd">mdi-help-circle</v-icon>
              <div style="width: 42px" v-else-if="$vuetify.breakpoint.width > 600"></div>
            </template>
            <span class="d-block">{{ service.info }}</span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <slot name="default"></slot>
  </div>
</template>

<script>
import globalMixins from "@/globalMixins";

export default {
  name: "AdditionalService",
  props: ['options', 'service', 'is_dry_service', 'selected_dry_service', 'is_dry_service_item', 'is_leather', 'dry_services_count'],
  emits: ['on-change-value', 'on-dry-service-select'],
  mixins: [globalMixins],
  data() {
    return {
      dry_section_opened: ''
    }
  },
  computed: {
    priceStringBuilder() {
      let price_before = this.service.units_after_price === '%' ? '+' : '';
      let price_body = this.sumSeparator(this.options.type === 'remont' && this.service.priceRemont ?
          this.service.priceRemont : this.service.price);
      let price_after = this.service.units_after_price + (this.service.units_after_price === '%' ? this.$store.state._(` к стоимости`) : '');
      let price_units = (this.service.step || this.service.units ? '/ ' : '') + (this.service.step || '') + this.service.units;
      return [price_before, price_body, price_after, price_units].filter(str => str).join(' ');
    },
    dryPriceStringBuilder() {
      let price_before = this.$store.state._(`от`) + (this.service.items.length && this.service.items[0].units_after_price === '%' ? '+' : '');
      let price_body = this.service.price_from;
      let price_after = '';
      if (this.service.items.length)
        price_after = this.service.items[0].units_after_price + (this.service.items[0].units_after_price === '%' ? this.$store.state._(`к стоимости`) : '');
      let price_units = this.service.units;
      return [price_before, price_body, price_after, price_units].join(' ');
    },
  },
  methods: {
    selectedSectionCount() {
      let count = 0;
      if (this.service && this.service.items) {
        this.service.items.forEach(item => {
          if (+this.options.services[item.form_name]) {
            count++;
          }
        });
      }
      return count;
    },
    minusval(val) {
      let value = Number(this.options[val]) || 0;
      if (value < 99) {
        this.options[val] = value - 1
      }
      this.$emit('on-change-value');
      this.$forceUpdate();
    },
    plusval(val) {
      if (!this.options[val]) this.options[val] = 0;
      let value = Number(this.options[val]) || 0;
      if (value < 99) {
        this.options[val] = value + 1
      }
      this.$emit('on-change-value');
      this.$forceUpdate();
    },
    onAddServiceClick() {
      if (this.service.form_name)
        this.options[this.service.form_name] = 1;
      this.$emit('on-change-value');
      if (this.service.key)
        this.$emit('on-dry-service-select', this.service.key);
      this.$forceUpdate();
    },
    onRemoveServiceClick() {
      if (this.service.form_name)
        this.options[this.service.form_name] = 0;
      this.$emit('on-change-value');
      this.$emit('on-dry-service-select', '');
      this.$forceUpdate();
    }
  }
}
</script>

<style scoped lang="scss">
.service.service-v2 {
  display: grid !important;
  grid-template-columns: 1fr 1fr 148px;
  border-radius: 0 !important;
  &:after {
    display: block!important;
    content: '';
    height: 1px;
    background: #DFDCDA;
    position: absolute;
    left: 30px;
    bottom: 0;
    right: 30px;
  }
  &.no-bottom-border {
    &:after {
      display: none !important;
    }
  }
  .btn-add, .btn-remove {
    width: 100% !important;
  }
}

@media screen and (max-width: 600px) {
  .service.service-v2.dry-service {
    grid-template-columns: 1fr 1fr 100px;
  }
}
</style>