<template>
  <div class="page-content" style="padding: 10px;">
    <div class="page-content-wrapper">
      <v-card :loading="loading_card"
              style="max-width: 400px;margin: auto;margin-top: 40px;min-height: 600px;display: flex;flex-direction: column;">
        <v-card-title>{{ $store.state._(`Оплата заказа`) }}</v-card-title>
        <v-card-subtitle v-if="order.datetimecleanObj">
          {{ order.datetimecleanObj.date }} {{ order.datetimecleanObj.time }}
        </v-card-subtitle>
        <v-card-text style="flex: 1;display: flex;flex-direction: column;">
          <v-alert v-if="error" outlined type="error">{{ error }}</v-alert>
          <v-alert v-else-if="success" outlined type="success">
            <div>{{ $store.state._(`Заказ оплачен`) }}</div>
          </v-alert>
          <div v-if="loading_state"
               style="display: flex;justify-content: center;align-items: center;flex: 1;padding-bottom: 100px;">
            <svg width="60" height="60" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#333">
              <g fill="none" fill-rule="evenodd">
                <g transform="translate(1 1)" stroke-width="2">
                  <circle stroke-opacity=".1" cx="18" cy="18" r="18"/>
                  <path d="M36 18c0-9.94-8.06-18-18-18">
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from="0 18 18"
                        to="360 18 18"
                        dur="1s"
                        repeatCount="indefinite"/>
                  </path>
                </g>
              </g>
            </svg>
          </div>
          <iframe v-else-if="paymentLink" :src="paymentLink"
                  style="border: none;width: 100%;height: 600px;border-radius: 5px;"/>

        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import {Server} from "@/server";

const server = new Server()
export default {
  name: 'Payment',
  data() {
    return {
      order: {datetimecleanObj: {}},
      params: {},
      error: '',
      paymentLink: '',
      orderID: '',
      success: false,
      loading_card: true,
      loading_state: false,
    };
  },
  methods: {
    getLink() {
      server.request('order/paymentLink/' + this.orderID, {}, (data) => {
        this.order = data.order;
        if (data.response === 'payed') {
          this.success = true;
        } else {
          this.paymentLink = data.response;
        }
        this.loading_card = false;
      }, (data) => {
        if (data.error) this.error = data.error;
        else this.error = this.$store.state._(`Не получилось загрузить информацию`);
        this.loading_card = false;
      });
    },
    checkState() {
      this.loading_state = true;
      server.request('order/paymentState/' + this.orderID, {paytureOrderID: this.order.paytureOrderID}, (data) => {
        if (data.response === 'payed') {
          this.success = true;
          this.loading_state = false;
          this.error = '';
          this.paymentLink = '';
        } else setTimeout(() => this.checkState(), 500);
      }, (data) => {
        if (data.error) this.error = data.error;
        else this.error = this.$store.state._(`Не получилось загрузить информацию`);
        this.loading_card = false;
      });
    },
  },
  mounted() {
    let parts = window.location.pathname.split('/').filter(c => c);
    console.log(parts);
    this.orderID = parts[parts.length - 2] + '/' + parts[parts.length - 1];
    this.getLink();
    // setTimeout(() => this.loading_state = true, 2000);
    // setTimeout(() => {
    //   this.success = true;
    //   this.loading_state = false;
    //   this.paymentLink = '';
    //   this.error = false;
    // }, 4000);
    window.addEventListener('message', (e) => {
      if (e.data === 'iframe_finished') {
        this.checkState();
      }
    })
  },
}
</script>
<style>
.v-application--wrap {
  min-height: unset;
}

* {
  color: #333333;
  padding: 0;
  margin: 0;
  font-family: Gerbera;
  font-weight: 400;
  /*letter-spacing: normal;*/
}
</style>