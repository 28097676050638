<template>
  <div class="order-form" :class="{'ceo-form-container': isCeoForm}" @click="toggleExpandForm">
    <div class="page-content form-content">
      <div v-if="!hiddenTitle" class="form-label">Закажите уборку сейчас</div>
      <v-form ref="form" :class="{'ceo-form': isCeoForm}">
        <div v-if="isRooms" class="cleantypes carousel-rooms flex-grow-1">
          <div v-if="showLabels" class="calculator-input-label">Количество комнат</div>
          <div class="cleantypes-options" style="margin-bottom: 12px">
            <v-carousel height="50"
                        hide-delimiters
                        :continuous="false"
                        @change="onChangeRoom"
                        :value="selectedRoom"
            >
              <v-carousel-item
                  class="cleantypes-options__option"
                  v-for="(i, value) in 5"
                  :key="value">
                {{ i }}-{{$store.state._('комнатная')}}
              </v-carousel-item>
              <template v-slot:prev="{on, attrs}">
                <div class="cleantypes-options--arrow cleantypes-options--prev"
                     v-bind="attrs"
                     v-on="on"
                >
                  <img
                      src="/dist/images/calculator/icon_chevron_right_black_thin.svg" alt=""
                      style="cursor: pointer;transform: rotate(180deg)">
                </div>
              </template>
              <template v-slot:next="{on, attrs}">
                <div class="cleantypes-options--arrow cleantypes-options--next"
                     v-bind="attrs"
                     v-on="on"
                >
                  <img
                      src="/dist/images/calculator/icon_chevron_right_black_thin.svg" alt=""
                      style="cursor: pointer">
                </div>
              </template>
            </v-carousel>
          </div>
        </div>
        <v-text-field
            v-if="!hiddenName"
            autocomplete="given-name"
            :rules="[v=>!!v || $store.state._(`Укажите имя`)]"
            v-model="formData.client.name"
            outlined
            name="name"
            rounded
            :placeholder="$store.state._(`Укажите имя`)"/>
        <div class="d-block phone-input">
          <div v-if="showLabels" class="calculator-input-label">Номер телефона</div>
          <v-text-field
              autocomplete="tel"
              :rules="[v=>!!v || $store.state._(`Укажите телефон`),
                v=>v && v.length >= $store.state.setMask(formData.client.phone).length || $store.state._(`Укажите телефон`),
                 ]"
              validate-on-blur
              v-model="formData.client.phone"
              outlined
              type="tel"
              pattern="[0-9]*"
              inputmode="numeric"
              name="phone"
              rounded
              @focus="onPhoneFocus()"
              @input="formData.client.phone = $store.state.checkPhone(formData.client.phone)"
              v-mask="$store.state.setMask(formData.client.phone)"
              :placeholder="$store.state.phone_placeholder"
          />
        </div>

          <transition name="expand">
            <div v-if="expandForm || showTypes" class="cleantypes carousel-types flex-grow-1">
              <div v-if="showLabels" class="calculator-input-label">Тип уборки</div>

              <div class="cleantypes-options" style="margin-bottom: 12px">
                <v-carousel height="50"
                            hide-delimiters
                            @change="applyCleanType"
                            :value="slideNum"
                >
                  <v-carousel-item
                      class="cleantypes-options__option"
                      v-for="(type, value) in types"
                      :key="value">
                    {{ type.title }}
                    <img
                        class="ml-1"
                        src="/dist/images/calculator/icon_question_grey.svg" alt=""
                         style="cursor: pointer; margin-bottom: 2px"
                         @click="showTypesModal">
                  </v-carousel-item>
                  <template v-slot:prev="{on, attrs}">
                    <div class="cleantypes-options--arrow cleantypes-options--prev"
                         v-bind="attrs"
                         v-on="on"
                    >
                      <img
                          src="/dist/images/calculator/icon_chevron_right_black_thin.svg" alt=""
                          style="cursor: pointer;transform: rotate(180deg)">
                    </div>
                  </template>
                  <template v-slot:next="{on, attrs}">
                    <div class="cleantypes-options--arrow cleantypes-options--next"
                         v-bind="attrs"
                         v-on="on"
                    >
                      <img
                          src="/dist/images/calculator/icon_chevron_right_black_thin.svg" alt=""
                          style="cursor: pointer">
                    </div>
                  </template>
                </v-carousel>
              </div>
            </div>
          </transition>
          <transition name="expand">

            <div v-if="expandForm && isExpandForm" class="date-and-time-wrapper new_date_time_picker">
              <v-menu
                  :left="$vuetify.breakpoint.width <= 960"
                  :top="$vuetify.breakpoint.width <= 960"
                  v-model="menu_date"
                  :close-on-content-click="false"
                  min-width="auto"
                  :content-class="$vuetify.breakpoint.width <= 960 ? 'datetime-modal-content' : ''"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      @click="menu_date=true"
                      :rules="[v=>!!v || $store.state._(`Выберите дату и время`)]"
                      autocomplete="off"
                      v-bind:value="formData.datetimecleanObj.date && formData.datetimecleanObj.time ? `${new Date(formData.datetimecleanObj.date).toLocaleDateString($store.state.local, {
          month: 'short', day: 'numeric', weekday: 'short' })}, ${$store.state._('в')} ${formData.datetimecleanObj.time}` : ''"
                      class="date"
                      readonly
                      :placeholder="$store.state._('Дата и время')"
                      outlined
                      rounded
                      v-bind="attrs"
                      v-on="on"
                      append-icon="mdi-chevron-down"
                      @click:append="menu_date=true"
                  />
                </template>
                <template v-if="isExpandForm">
                  <div v-if="$vuetify.breakpoint.width <= 960" class="mobile-datetime-picker">
                    <v-btn icon class="mobile-datetime-picker-close" @click="menu_date = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <span class="picker-label">{{ $store.state._('Дата') }}</span>
                    <div class="date-slots-container" v-if="datetimeProps.dateSlots.length">
                      <div class="date-slot" :class="{'selected': formData.datetimecleanObj.date === slot.date}"
                           v-for="slot in datetimeProps.dateSlots" :key="slot.date"
                           :style="`border-color: ${slot.color}`"
                           @click="formData.datetimecleanObj.date = slot.date;formData.datetimecleanObj.time = ''; calculate()">
                        <span>{{
                            `${new Date(slot.date).toLocaleDateString($store.state.local, {day: 'numeric'})}`
                          }}</span>
                        <span>{{
                            `${new Date(slot.date).toLocaleDateString($store.state.local, {weekday: 'short'})}`
                          }}</span>
                      </div>
                    </div>
                    <span class="picker-label">{{ $store.state._('Время') }}</span>
                    <div class="time-picker-container">
                      <div class="time-picker-outer" v-for="(interval, i) in datetimeProps.timeSlots" :key="i">
                        <template v-if="interval.length">
                          <span class="time-picker-label">{{ $store.state._(i === 0 ? 'Утро' : 'День') }}</span>
                          <div class="time-slot-items">
                            <div class="time-slot-item-container" v-for="slot in interval" :key="slot.time"
                                 @click="formData.datetimecleanObj.time = slot.time; calculate()">
                              <div class="time" :class="{'selected': slot.time === formData.datetimecleanObj.time}"
                                   :style="`border-color: ${slot.color || '#C8C6C6'}`">{{ slot.time }}
                              </div>
                              <div class="percent" :style="`color: ${slot.color || '#333'}`">{{ slot.percent }}</div>
                            </div>
                          </div>
                        </template>
                      </div>
                      <div class="mobile-datetime-picker-actions">
                        <v-btn class="v-btn-main time-picker-button" @click="menu_date = false"
                               :disabled="formData.datetimecleanObj.time === ''">
                          {{ $store.state._('Продолжить') }}
                          <span class="old-sum d-block"
                                v-if="parseInt(calculatedProps.totalCost) > parseInt(calculatedProps.totalPay)"
                          >{{
                              calculatedProps.totalCost ? sumSeparator(calculatedProps.totalCost) : 0
                            }} {{ countrySettings.currency }}</span>

                          <span class="d-block">от {{
                              calculatedProps.totalPay ? sumSeparator(calculatedProps.totalPay) : 0
                            }} {{ countrySettings.currency }}</span>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                  <v-date-picker
                      v-else
                      :events="datetimeProps.dateSlots.map(a=>a.date)"
                      :event-color="(date)=>datetimeProps.dateSlots.find(a=>a.date===date) ? datetimeProps.dateSlots.find(a=>a.date===date).color : ''"
                      no-title
                      reactive
                      v-model="formData.datetimecleanObj.date"
                      locale="ru"
                      width="334px"
                      first-day-of-week="1"
                      @input="formData.datetimecleanObj.time = ''; calculate()"
                      :min="new Date().toISOString()"
                  >
                    <template #default v-if="datetimeProps.dateSlots.length">
                      <div class="time-picker-container">
                        <div class="time-picker-outer" v-for="(interval, i) in datetimeProps.timeSlots" :key="i">
                          <template v-if="interval.length">
                            <span class="time-picker-label">{{ $store.state._(i === 0 ? 'Утро' : 'День') }}</span>
                            <div class="time-slot-items">
                              <div class="time-slot-item-container" v-for="slot in interval" :key="slot.time"
                                   @click="formData.datetimecleanObj.time = slot.time; calculate()">
                                <div class="time" :class="{'selected': slot.time === formData.datetimecleanObj.time}"
                                     :style="`border-color: ${slot.color || '#C8C6C6'}`">{{ slot.time }}
                                </div>
                                <div class="percent" :style="`color: ${slot.color || '#333'}`">{{ slot.percent }}</div>
                              </div>
                            </div>
                          </template>
                        </div>
                        <div class="mobile-datetime-picker-actions">
                          <v-btn class="v-btn-main time-picker-button" @click="menu_date = false"
                                 :disabled="formData.datetimecleanObj.time === ''">
                            {{ $store.state._('Продолжить') }}
                            <span class="old-sum d-block"
                                  v-if="parseInt(calculatedProps.totalCost) > parseInt(calculatedProps.totalPay)"
                            >{{
                                calculatedProps.totalCost ? sumSeparator(calculatedProps.totalCost) : 0
                              }} {{ countrySettings.currency }}</span>

                            <span class="d-block">от {{
                                calculatedProps.totalPay ? sumSeparator(calculatedProps.totalPay) : 0
                              }} {{ countrySettings.currency }}</span>
                          </v-btn>
                        </div>
                      </div>
                    </template>
                  </v-date-picker>
                </template>
              </v-menu>
              <!--      <span class="high-demand"
                          v-if="calculatedProps.koef > 1 && calculatedProps.koefObj.titleSmall && $vuetify.breakpoint.width < 600">{{
                        calculatedProps.koefObj.titleSmall
                      }}</span>
                    <span class="discount"
                          v-if="calculatedProps.koef < 1  && $vuetify.breakpoint.width < 600">{{
                        $store.state._(`Вам повезло, низкий спрос! Дешевле на`)
                      }} {{
                        Math.abs(calculatedProps.koef * 100 - 100)
                      }}%</span>-->
            </div>
          </transition>
          <transition name="expand">
            <v-textarea
                v-if="expandForm && isExpandForm"
                autocomplete="off"
                outlined
                v-model="formData.comment"
                :placeholder="$store.state._(`Комментарий к заказу`)"
                rows="3"
                no-resize
                style="border-radius: 12px;"
            />
          </transition>
      </v-form>
    </div>
    <div class="form-actions">
      <div v-if="showTotal" class="calculator-total">{{$store.state._('от')}} {{calculatedProps.totalPay}} {{countrySettings.currency}}</div>
      <v-btn class="v-btn-main" @click="submit" :loading="loading_create">{{ $store.state._(`Заказать`) }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import {Server} from "@/server";

const server = new Server()

export default {
  name: "OrderForm",
  data() {
    let types = {
      standart: {
        title: this.$store.state._(`Регулярная`),
        tip: this.$store.state._(`Поддержание уюта и чистоты в вашем доме`)
      },
      general: {
        title: this.$store.state._(`Генеральная`),
        tip: this.$store.state._(`Подходит, если давно не убирались, была вечеринка или сменились жильцы`)
      },
      remont: {
        title: this.$store.state._(`После ремонта`),
        tip: this.$store.state._(`Поставим финальную точку в ремонте — очистим квартиру от строительной пыли и пятен`)
      },
    };
    let typesNames = Object.keys(types);
    return {
      formData: {
        client: {
          phone: '',
        },
        type: 'standart',
        datetimecleanObj: {time: '', date: ''},
        address: {}
      },
      slideNum: typesNames.indexOf(window.cleanType || 'standart'),
      types,
      typesNames,
      menu_date: false,
      datetimeProps: {dateSlots: [], timeSlots: []},
      loading_create: false,
      expandForm: false,
      calculatedProps: {},
      countrySettings: {},
      isExpandForm: true,
      isRooms: false,
      selectedRoom: 0,
      hiddenName: false,
      showTypes: false,
      showTotal: false,
      showLabels: false,
      hiddenTitle: false,
      isCeoForm: false,
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.loading_create = true
        server.request('order/create', this.formData, (data) => {
          this.loading_create = false;
          try {
            window.dataLayer.push({event: 'callback_order'});
          } catch (e) {
            console.log(e);
          }
          location.href = window.href('/order');
        }, (data) => {
          alert(data.error || this.$store.state._(`Не получилось оформить заказ`))
          this.loading_create = false
        })
      }
    },
    calculate() {
      server.request('order/calculate', this.formData, (data) => {
        this.datetimeProps.dateSlots = data.date_slots || [];
        this.datetimeProps.timeSlots = data.time_slots || [];
        this.calculatedProps = data.response;
        this.countrySettings = data.country;
        if (!this.formData.datetimecleanObj.date && this.datetimeProps.dateSlots.length) {
          this.formData.datetimecleanObj.date = this.datetimeProps.dateSlots[1].date;
          this.calculate();
        }
        this.$forceUpdate();
      })
    },
    onPhoneFocus() {
      if (this.$store.state.phone_placeholder[1] === '7' && !this.formData.client.phone.length) {
        this.formData.client.phone = '+7 (';
      }
    },
    applyCleanType(typeNum) {
      this.formData.type = this.typesNames[typeNum];
      this.calculate();
    },
    showTypesModal() {
      window.showModal(document.querySelector('.modal_types_cleaning'));
    },
    sumSeparator(sum) {
      return String(sum)
          .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 '); // 1 000 000 000
    },
    toggleExpandForm() {
      if (this.isExpandForm) {
        this.expandForm = true;
        document.querySelector('.head_image .header-bg-image.desktop').classList.add('expanded');
      }
    },
    onChangeRoom(val) {
      this.formData.address.flattype = val+1;
      this.calculate();
    }
  },
  mounted() {
    if (window.cleanType) this.formData.type = window.cleanType;
    if (window.withoutExpandForm) this.isExpandForm = false;
    if (window.isRooms) this.isRooms = true;
    if (window.hiddenName) this.hiddenName = true;
    if (window.showTypes) this.showTypes = true;
    if (window.showTotal) this.showTotal = true;
    if (window.hiddenTitle) this.hiddenTitle = true;
    if (window.showLabels) this.showLabels = true;
    if (window.isCeoForm) this.isCeoForm = true;
    this.calculate();
  }
}
</script>

<style scoped lang="scss">
.order-form {
  background: #FFFFFF;
  border-radius: 12px;
  width: 466px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-label {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
}

.form-content.page-content {
  padding: 36px 36px 16px;
  width: 100%;
}

.ceo-form-container .form-content {
  padding: 32px 32px 0;
}


.form-actions {
  padding: 28px 36px 36px;
  width: 100%;
  border-top: 1px solid #D6D0CD;
}

.ceo-form-container .form-actions {
  padding: 20px 36px 24px;
}

.cleantypes-options {
  margin-left: 0 !important;
  margin-bottom: 12px;
  width: 100% !important;
  border: 1px solid #D6D0CD;
  max-width: 100% !important;
}

.time-picker-container {
  width: 100%;
  padding: 12px;

  .time-slot-items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 24px 8px;
    margin-bottom: 33px;

    .time-slot-item-container {
      position: relative;
      cursor: pointer;

      .time {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #C8C6C6;
        border-radius: 8px;
        font-size: 15px;
        line-height: 140%;

        &.selected {
          background: #333333;
          color: #FFFFFF;
        }
      }

      .percent {
        text-align: center;
        position: absolute;
        width: 100%;
        top: 44px;
        font-size: 11px;
        line-height: 120%;
      }
    }
  }

  .time-picker-button {
    justify-content: space-between;
    letter-spacing: normal;

    .v-btn__content {
      font-size: 14px;
    }

    .old-sum {
      margin-left: auto;
      margin-right: 8px;
      color: #B5B0AC;
      text-decoration: line-through;
    }
  }
}

.date-and-time-wrapper {
  display: flex;
  column-gap: 8px;

  .date {
    flex-basis: 60%;
    align-items: flex-end;
  }

  .time {
    align-items: flex-end;
    flex-basis: 40%;
  }
}

.mobile-datetime-picker {
  background: #FFFFFF;
  width: 100%;
  height: 100%;
  padding: 40px 20px 0;
  display: flex;
  flex-direction: column;
  position: relative;

  &-close.v-btn {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .picker-label {
    margin-bottom: 8px;
    display: block;
    font-size: 18px;
    line-height: 130%;
  }

  .date-slots-container::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .date-slots-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .date-slots-container {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    margin-bottom: 40px;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;

    .date-slot {
      min-width: 46px;
      width: 46px;
      height: 46px;
      border-radius: 50%;
      border: 1px solid #C8C6C6;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 15px;

      &.selected {
        background: #333333;

        span {
          color: #FFFFFF;
        }
      }

      span {
        line-height: 100%;
        height: 15px;
        display: block;
        text-align: center;
      }
    }
  }

  .time-picker-container {
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &-actions {
    padding-bottom: 20px;
    position: relative;
    bottom: auto;
    width: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    margin-top: 0;
    z-index: 1;
  }
}

.v-text-field__details {
  margin-top: 3px;
  margin-left: 10px;
}

@media screen and (max-width: 768px) {
  .order-form {
    width: 100%;
  }
}

.expand-enter-active, .expand-leave-active {
  transition: all 1s ease;
  overflow: hidden;
}

.expand-enter, .expand-leave-to /* .expand-leave-active до версии 2.1.8 */
{
  max-height: 0;
  opacity: 0;
}

.expand-enter-to, .expand-leave {
  max-height: 1000px; /* это значение должно быть больше максимальной высоты поля */
  opacity: 1;
}

.calculator-total {
  margin-bottom: 24px;
  font-size: 38px;
  text-align: center;
}

.calculator-input-label {
  margin-bottom: 12px;
  font-size: 16px;
  color: #B5B0AC;
}

.ceo-form {
  display: flex;
  flex-direction: column;
  .carousel-rooms {
    order: 1;
  }
  .carousel-types {
    order: 2;
  }
  .phone-input {
    order:3;
  }
}

@media screen and (max-width: 575px) {
  .calculator-input-label {
    display: none;
  }
}
</style>