<template>
  <v-dialog
      :width="$vuetify.breakpoint.width < 768 ? 482 : $vuetify.breakpoint.width < 375 ? 315 : 568"
      v-model="opened"
      :retain-focus="false"
      persistent
      :hide-overlay="hideOverlay"
      @keyup.esc="close"
  >
    <div class="discount-popup">
      <v-btn
          @click.prevent="close"
          class="close-btn"
          icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <div class="discount-title">Привет от друга!</div>
      <div class="discount-text">Лови 1000₽ на поддержание уюта в доме.<br>Введи свой номер телефона:
      </div>
      <v-text-field
          autocomplete="off"
          class="phone"
          v-model="phone"
          outlined
          rounded
          v-mask="$store.state.setMask(phone)"
          :placeholder="$store.state.phone_placeholder"
      />
      <v-btn class="v-btn-main get-promo"
             @click="getPromo"
      >Заказать уборку</v-btn>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "PopupRef",
  props: {
    hideOverlay: {default: false},
  },
  data() {
    return {
      phone: '',
      opened: false
    }
  },
  methods: {
    close() {
      this.opened = false;
    },
    getPromo() {
      this.$eventBus.$emit('friendPromo', {phone: this.phone})
      this.opened  = false
    }
  }
}
</script>

<style scoped lang="scss">
.discount-popup {
  background: url("https://cleanbros.ru/dist/images/popup_bg.png") center center no-repeat;
  background-size: cover;
  border-radius: 16px;
  padding: 76px 52px;
  position: relative;
  background-color: white;

  .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1;
  }

  .star {
    position: absolute;
    top: 20px;
    right: 16px;
    font-weight: 500;
    font-size: 24px;
    line-height: 112%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: url("https://cleanbros.ru/dist/images/star.svg");
    background-size: cover;
    width: 180px;
    height: 180px;
  }

  .discount-title {
    font-weight: 500;
    font-size: 36px;
    line-height: 120%;
    margin-bottom: 20px;
    max-width: 320px;
  }

  .discount-text {
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 40px;
    }
  }

  .discount-email-input {
    width: 100%;
    margin-bottom: 11px;
  }

  .get-promo {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .discount-popup {
    padding: 72px 44px 64px;
    .star {
      width: 165px;
      height: 165px;
      font-size: 22px;
      top: 16px;
    }
    .discount-title {
      font-size: 32px;
      z-index: 1;
      position: relative;
    }
    .discount-text {
      font-size: 16px;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 30px;
      }
    }
  }
}
@media screen and (max-width: 575px) {
  .discount-popup {
    padding: 121px 28px 64px;
    .discount-title {
      font-size: 30px;
    }
    .discount-text {
      font-size: 16px;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 32px;
      }
    }
    .star {
      width: 135px;
      height: 135px;
      font-size: 18px;
    }
  }
}
</style>