<template>
  <div class="calculator" ref="calculator">
    <Popup ref="Popup"/>
    <PopupRef ref="PopupRef"/>
    <div class="page-content">
      <div class="page-content-wrapper">
        <div class="options-wrapper" style="overflow: hidden;">
          <div class="flat-options" :class="{'cottage-options': isCottage}">
            <div v-if="!isCottage" class="room-number">
              <div class="room-number__title options__title">
                {{ $store.state._(`Количество комнат`) }}
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        class="ml-2"
                        v-bind="attrs"
                        v-on="on"
                        style="margin-top: -2px;"
                        color="#d6d0cd">
                      mdi-help-circle
                    </v-icon>
                  </template>
                  <span>
                    <div v-for="(interval, rooms) in flatSize" :key="rooms" style="color: #FFFFFF">
{{ rooms }} {{ $store.state._(`комн.`) }} - {{ interval[0] }}-{{ interval[1] }} {{ $store.state._(`м²`) }}<br>
                    </div>
                  </span>
                </v-tooltip>
              </div>
              <div class="room-number-options">
                <div
                    @click="options.address.flattype = value; calculate()"
                    :class="options.address.flattype === value ? 'room-number-options__option--selected' : ''"
                    class="room-number-options__option" v-for="(room_option, value) in room_options" :key="value">
                  {{ room_option }}
                </div>
              </div>
              <div class="sqr-wrapper" v-if="options.address.flattype === '5'">
                <v-text-field
                    class="sqr__value"
                    placeholder="150"
                    outlined
                    rounded
                    v-model="options.address.sqr"
                    @change="calculate()"
                    autocomplete="off"
                />
                <div class="sqr__label">{{ $store.state._(`м²`) }}</div>
              </div>
            </div>
            <div class="cleantypes flex-grow-1">
              <div class="cleantypes__title options__title">{{ $store.state._(`Тип уборки`) }}</div>
              <div class="cleantypes-options">
                <v-carousel height="50"
                            hide-delimiters
                            @change="applyCleanType"
                            :value="slideNum"
                >
                  <v-carousel-item
                      class="cleantypes-options__option"
                      v-for="(type, value) in cleantype_options"
                      :key="value">
                    {{ type.title }}
                    <v-icon
                        style="cursor: pointer"
                        @click="showTypesModal"
                        class="ml-2"
                        color="rgb(173, 170, 169)">
                      mdi-information-outline
                    </v-icon>
                    <!--                    <v-tooltip bottom>-->
                    <!--                      <template v-slot:activator="{ on, attrs }">-->
                    <!--                        <v-icon-->
                    <!--                          class="ml-2"-->
                    <!--                          v-bind="attrs"-->
                    <!--                          v-on="on"-->
                    <!--                          color="#d6d0cd">-->
                    <!--                          mdi-help-circle-->
                    <!--                        </v-icon>-->
                    <!--                      </template>-->
                    <!--                      <span>{{ type.tip }}</span>-->
                    <!--                    </v-tooltip>-->
                  </v-carousel-item>
                  <template v-slot:prev="{on, attrs}">
                    <div class="cleantypes-options--arrow cleantypes-options--prev"
                         v-bind="attrs"
                         v-on="on"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </div>
                  </template>
                  <template v-slot:next="{on, attrs}">
                    <div class="cleantypes-options--arrow cleantypes-options--next"
                         v-bind="attrs"
                         v-on="on"
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </div>
                  </template>
                </v-carousel>
              </div>
            </div>
            <div class="sqr-wrapper" v-if="isCottage">
              <v-text-field
                  class="sqr__value"
                  placeholder="150"
                  outlined
                  rounded
                  v-model="options.address.sqr"
                  @change="calculate()"
                  autocomplete="off"
              />
              <div class="sqr__label">{{ $store.state._(`м²`) }}</div>
            </div>
          </div>

          <div class="additional-services">
            <div class="options__title d-flex mb-4 align-center">
              <span>{{ $store.state._(`Дополнительные услуги`) }}</span>
              <v-btn class="ml-auto" @click="clearForm">Очистить</v-btn>
            </div>
            <div class="additional-services--wrapper overflow-hidden">
              <AdditionalService :options="options"
                                 v-for="(service, index) in showedAll ? additional_services : additional_services.slice(0, 3)"
                                 :key="index" :service="service"
                                 @on-change-value="calculate"/>
              <template v-if="dryCleaningServicesEnabled">
                <AdditionalService :service="dry_service" :options="options" :is_dry_service="true"
                                   :dry_services_count="countSelectedDryServices()"/> <!--Химчистка-->
                <template v-if="+options['him']">
                  <AdditionalService v-for="dry_service in dry_cleaning_services"
                                     @on-change-value="calculate"
                                     :key="dry_service.key" :service="dry_service"
                                     :options="options"
                                     :is_dry_service="true"
                                     :selected_dry_service="selected_dry_service"
                                     @on-dry-service-select="onDryServiceSelected">
                    <template #default>
                      <div class="sub-services" v-if="selected_dry_service === dry_service.key">
                        <AdditionalService v-for="dry_service_item in dry_service.items" :options="options.services"
                                           @on-change-value="calculate"
                                           :is_dry_service_item="true"
                                           :service="dry_service_item" :key="dry_service_item.form_name">
                          <AdditionalService v-if="+options.services[dry_service_item.form_name]"
                                             @on-change-value="calculate"
                                             :is_leather="true"
                                             :options="options.services"
                                             :service="{
                                             form_name: dry_service_item.form_name+'_him_koja',
                                             name: $store.state._(`Изделие из кожи`), price:30,units:'',units_after_price:'%'}"/>
                        </AdditionalService>
                      </div>
                    </template>
                  </AdditionalService>
                </template>
              </template>
              <div class="collapse-btn-wrapper">
                <v-btn
                    class="v-btn-main" @click="showedAll = !showedAll">
                  {{
                    !showedAll ? $store.state._(`+ Ещё ${additional_services.length - 4} опций`) : $store.state._(`Свернуть`)
                  }}
                </v-btn>
              </div>
            </div>
          </div>
        </div>
        <div class="side-wrapper total-wrapper" v-if="order_step === 0">
          <div class="total">
            <p class="total__desc">
              <template v-if="(+options.address.flattype) === 1">
                <template v-if="(+options.address.wcNum) === 1">
                  {{
                    $store.state._(`Уборка квартиры с _rooms_ жилой комнатой и одним санузлом`).split('_rooms_').join(room_options[1])
                  }}
                </template>
                <template v-else>
                  {{
                    $store.state._(`Уборка квартиры с _rooms_ жилой комнатой и _count_ санузлами`).split('_rooms_').join(room_options[1]).split('_count_').join(options.address.wcNum)
                  }}
                </template>
              </template>
              <template v-else>
                <template v-if="(+options.address.wcNum) === 1">
                  {{
                    $store.state._(`Уборка квартиры с _rooms_ жилыми комнатами и одним санузлом`).split('_rooms_').join(room_options[+options.address.flattype])
                  }}
                </template>
                <template v-else>
                  {{
                    $store.state._(`Уборка квартиры с _rooms_ жилыми комнатами и _count_ санузлами`).split('_rooms_').join(room_options[+options.address.flattype]).split('_count_').join(options.address.wcNum)
                  }}
                </template>
              </template>
            </p>
            <div class="selected-services">
              <div class="selected-service"
                   v-for="service in additional_services.filter(serv=>parseInt(options[serv.form_name]) > parseInt(serv.form_value))"
                   :key="service.form_name"
              >
                <div class="name">{{ service.name }}</div>
                <div class="price" v-if="service.form_name === 'address.wcNum'">
                  {{
                    sumSeparator(Number(options.type == 'remont' && service.priceRemont ? service.priceRemont : service.price) * options[service.form_name] - Number(options.type == 'remont' && service.priceRemont ? service.priceRemont : service.price))
                  }} {{ service.units_after_price }}
                </div>
                <div class="price" v-else-if="service.units_after_price === '%'">
                  {{
                    calculatedProps.additionServices ? calculatedProps.additionServices[service.form_name] : ''
                  }}{{ countrySettings.currency }}
                </div>
                <div class="price" v-else>{{
                    sumSeparator(Number(options.type == 'remont' && service.priceRemont ? service.priceRemont : service.price) * options[service.form_name])
                  }} {{
                    service.units_after_price
                  }}
                </div>
                <v-btn class="v-btn-delete-service" icon
                       @click="options[service.form_name] = 0; $forceUpdate(); calculate()">
                  <v-icon size="16" color="#333">mdi-close</v-icon>
                </v-btn>
              </div>
              <div class="selected-service"
                   v-for="service in dry_cleaning_services.flatMap(dry_serv=>dry_serv.items).filter(serv=>+options.services[serv.form_name] > +serv.form_value)"
                   :key="service.key">
                <div class="name">{{ service.name }}</div>
                <div class="price" v-if="service.units_after_price === '%'">
                  {{
                    calculatedProps.additionServices ? calculatedProps.additionServices[service.form_name] : ''
                  }}{{ countrySettings.currency }}
                </div>
                <div class="price" v-else>{{
                    sumSeparator(+service.price * +options.services[service.form_name])
                  }} {{
                    service.units_after_price
                  }}
                </div>
                <v-btn class="v-btn-delete-service" icon
                       @click="options.services[service.form_name] = 0; $forceUpdate(); calculate()">
                  <v-icon size="16" color="#333">mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
            <div class="discount-wrapper"
                 v-if="parseInt(calculatedProps.totalCost) > parseInt(calculatedProps.totalPay) && calculatedProps.promoInfo && !options.promo">
              <span>{{ calculatedProps.promoInfo.title }}</span>
            </div>
            <div class="total-sum-wrapper">
              <div class="total-sum total-sum-wrapper__title align-self-start">{{ $store.state._(`К оплате`) }}</div>
              <div v-if="calculate_loading" class="total-sum-wrapper__sum skeleton-loader"
                   style="height: 38px; width: 105px; border-radius: 8px"/>
              <div v-else class="total-sum-wrapper__sum">
                <span class="d-block">{{
                    calculatedProps.totalPay ? sumSeparator(calculatedProps.totalPay) : 0
                  }} {{ countrySettings.currency }}</span>
                <span class="old-sum d-block"
                      v-if="parseInt(calculatedProps.totalCost) > parseInt(calculatedProps.totalPay)"
                >{{
                    calculatedProps.totalCost ? sumSeparator(calculatedProps.totalCost) : 0
                  }} {{ countrySettings.currency }}</span>
              </div>
            </div>
            <div class="duration-wrapper" v-if="calculatedProps.anotherCurrency" style="justify-content: end;">
              {{ calculatedProps.anotherCurrency }}
            </div>
            <div class="duration-wrapper" v-if="calculatedProps.bonus">
              {{ $store.state._(`Будет списано 100 бонусов`).split('100').join(calculatedProps.bonus) }}
            </div>
            <div class="duration-wrapper">
              <div>{{ $store.state._(`Примерное время уборки`) }}</div>
              <div v-if="calculate_loading" class="skeleton-loader"
                   style="width: 72px; height: 21px;border-radius: 24px"></div>
              <div v-else>
                {{ calculatedProps.duration }}
              </div>
            </div>
            <div class="duration-wrapper"
                 v-if="calculatedProps.workersCount && ![0,1].includes(+calculatedProps.workersCount)">
              <div>{{ $store.state._(`Ориентировочное кол-во клинеров`) }}</div>
              <div v-if="calculate_loading" class="skeleton-loader"
                   style="width: 60px; height: 21px;border-radius: 24px"></div>
              <div v-else>
                {{ calculatedProps.workersCount }}
              </div>
            </div>
            <div class="high-demand-wrapper"
                 v-if="calculatedProps.koef > 1 && calculatedProps.koefObj.title || calculatedProps.koef < 1">
              {{
                calculatedProps.koef > 1 ? calculatedProps.koefObj.title : `${$store.state._('Скидка')}! (-${Math.abs(calculatedProps.koef * 100 - 100)}%)`
              }}

              <span v-if="calculatedProps.koefObj.subtitle">{{ calculatedProps.koefObj.subtitle }}</span>
            </div>
          </div>
          <div class="order-info">
            <v-form ref="form_orderInfo">
              <v-text-field
                  autocomplete="given-name"
                  :rules="[v=>!!v || $store.state._(`Укажите имя`)]"
                  v-model="options.client.name"
                  outlined
                  autofocus
                  name="name"
                  rounded
                  :placeholder="$store.state._(`Укажите имя`)"/>
              <v-text-field
                  autocomplete="tel"
                  :rules="[v=>!!v || $store.state._(`Укажите телефон`),
                v=>v && v.length >= $store.state.setMask(options.client.phone).length || $store.state._(`Укажите телефон`),
                 ]"
                  validate-on-blur
                  class="phone"
                  @change="calculate"
                  v-model="options.client.phone"
                  outlined
                  type="tel"
                  pattern="[0-9]*"
                  inputmode="numeric"
                  name="phone"
                  rounded
                  @focus="onPhoneFocus()"
                  @input="options.client.phone = $store.state.checkPhone(options.client.phone);"
                  v-mask="$store.state.setMask(options.client.phone)"
                  :placeholder="$store.state.phone_placeholder"
              />
              <div class="date-and-time-wrapper"
                   :class="isNewDatetimePicker?'new_date_time_picker':'old_datetime_picker'">
                <v-menu
                    :left="!!(isNewDatetimePicker && $vuetify.breakpoint.width <= 960)"
                    :top="!!(isNewDatetimePicker && $vuetify.breakpoint.width <= 960)"
                    v-model="menu_date"
                    :close-on-content-click="false"
                    :close-on-click="!timeDisabledModalOpened"
                    min-width="auto"
                    :content-class="isNewDatetimePicker && $vuetify.breakpoint.width <= 960 ? 'datetime-modal-content' : ''"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        @click="menu_date=true"
                        :rules="[v=>!!v || $store.state._(isNewDatetimePicker?`Выберите дату и время`:`Выберите дату`)]"
                        autocomplete="off"
                        v-bind:value="(options.datetimecleanObj.date && (!isNewDatetimePicker || options.datetimecleanObj.time)) ? `${new Date(options.datetimecleanObj.date).toLocaleDateString($store.state.local, {
          month: 'short', day: 'numeric', weekday: 'short' })}${isNewDatetimePicker ? `, ${$store.state._('в')} ${options.datetimecleanObj.time}` : ''}` : ''"
                        class="date"
                        readonly
                        :placeholder="$store.state._(isNewDatetimePicker ? 'Дата и время' :`Дата уборки`)"
                        outlined
                        rounded
                        v-bind="attrs"
                        v-on="on"
                        append-icon="mdi-chevron-down"
                        @click:append="menu_date=true"
                    />
                  </template>
                  <div v-if="isNewDatetimePicker && $vuetify.breakpoint.width <= 960" class="mobile-datetime-picker">
                    <v-btn icon class="mobile-datetime-picker-close" @click="menu_date = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <span class="picker-label">{{ $store.state._('Дата') }}</span>
                    <div class="date-slots-container" v-if="datetimeProps.dateSlots.length">
                      <div class="date-slot" :class="{'selected': options.datetimecleanObj.date === slot.date}"
                           v-for="slot in datetimeProps.dateSlots" :key="slot.date"
                           :style="`border-color: ${slot.color}`"
                           @click="selectDateMobile(slot.date)">
                        <span>{{
                            `${new Date(slot.date).toLocaleDateString($store.state.local, {day: 'numeric'})}`
                          }}</span>
                        <span>{{
                            `${new Date(slot.date).toLocaleDateString($store.state.local, {weekday: 'short'})}`
                          }}</span>
                      </div>
                    </div>
                    <span class="picker-label">{{ $store.state._('Время') }}</span>
                    <template v-if="calculate_date_loading">
                      <div class="time-picker-container">
                        <div class="time-picker-outer" v-for="(interval, i) in [[8], [7]]" :key="i">
                          <template v-if="interval.length">
                            <span class="time-picker-label">{{ $store.state._(i === 0 ? 'Утро' : 'День') }}</span>
                            <div class="time-slot-items">
                              <div class="time-slot-item-container" v-for="slot in 8 - i" :key="slot">
                                <div class="time skeleton-loader"></div>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </template>
                    <div class="time-picker-container">
                      <template v-if="!calculate_date_loading">
                        <div class="time-picker-outer" v-for="(interval, i) in datetimeProps.timeSlots" :key="i">
                          <template v-if="interval.length">
                            <span class="time-picker-label">{{ $store.state._(i === 0 ? 'Утро' : 'День') }}</span>
                            <div class="time-slot-items">
                              <div class="time-slot-item-container" v-for="slot in interval" :key="slot.time"
                                   @click="chooseTime(slot)">
                                <div class="time" :class="{'selected': slot.time === options.datetimecleanObj.time}"
                                     :style="`border-color: ${slot.color || '#C8C6C6'};
                                   color: ${slot.time === options.datetimecleanObj.time ? '#fff' : slot.fontColor || '#333'}`">
                                  {{ slot.time }}
                                </div>
                                <div class="percent" :style="`color: ${slot.color || '#333'}`">{{ slot.percent }}</div>
                              </div>
                            </div>
                          </template>
                        </div>
                      </template>
                      <div class="mobile-datetime-picker-actions">
                        <v-btn class="v-btn-main time-picker-button" @click="menu_date = false"
                               :disabled="options.datetimecleanObj.time === ''">
                          {{ $store.state._('Продолжить') }}
                          <span class="old-sum d-block"
                                v-if="parseInt(calculatedProps.totalCost) > parseInt(calculatedProps.totalPay)"
                          >{{
                              calculatedProps.totalCost ? sumSeparator(calculatedProps.totalCost) : 0
                            }} {{ countrySettings.currency }}</span>

                          <span class="d-block">{{
                              calculatedProps.totalPay ? sumSeparator(calculatedProps.totalPay) : 0
                            }} {{ countrySettings.currency }}</span>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                  <v-date-picker
                      v-else
                      :events="datetimeProps.dateSlots.map(a=>a.date)"
                      :event-color="(date)=>datetimeProps.dateSlots.find(a=>a.date===date) ? datetimeProps.dateSlots.find(a=>a.date===date).color : ''"
                      no-title
                      reactive
                      v-model="options.datetimecleanObj.date"
                      locale="ru"
                      width="334px"
                      first-day-of-week="1"
                      @input="onDateChange()"
                      :min="new Date().toISOString()"
                      @change="calculate()"
                  >
                    <template #default v-if="datetimeProps.dateSlots.length">
                      <div class="time-picker-container">
                        <div v-if="calculate_date_loading">
                          <div class="time-slot-items">
                            <div class="time-slot-item-container" v-for="i in 11" :key="i">
                              <div class="time skeleton-loader"></div>
                            </div>
                          </div>
                        </div>
                        <div v-else class="time-slot-items">
                          <div class="time-slot-item-container" v-for="slot in timeSlots" :key="slot.time"
                               @click="chooseTime(slot)">
                            <div class="time" :class="{'selected': slot.time === options.datetimecleanObj.time}"
                                 :style="`border-color: ${slot.color || '#C8C6C6'};
                                 color: ${slot.time === options.datetimecleanObj.time ? '#FFF' : slot.fontColor || '#333'}`">
                              {{ slot.time }}
                            </div>
                            <div class="percent" :style="`color: ${slot.color || '#333'}`">{{ slot.percent }}</div>
                          </div>
                        </div>
                        <v-btn class="v-btn-main time-picker-button" @click="menu_date = false">
                          {{ $store.state._('Продолжить') }}
                          <span class="old-sum d-block"
                                v-if="parseInt(calculatedProps.totalCost) > parseInt(calculatedProps.totalPay)"
                          >{{
                              calculatedProps.totalCost ? sumSeparator(calculatedProps.totalCost) : 0
                            }} {{ countrySettings.currency }}</span>

                          <span class="d-block">{{
                              calculatedProps.totalPay ? sumSeparator(calculatedProps.totalPay) : 0
                            }} {{ countrySettings.currency }}</span>
                        </v-btn>
                      </div>
                    </template>
                  </v-date-picker>
                </v-menu>
                <span class="high-demand"
                      v-if="calculatedProps.koef > 1 && calculatedProps.koefObj.titleSmall && $vuetify.breakpoint.width < 600">{{
                    calculatedProps.koefObj.titleSmall
                  }}</span>
                <span class="discount"
                      v-if="calculatedProps.koef < 1  && $vuetify.breakpoint.width < 600">{{
                    $store.state._(`Вам повезло, низкий спрос! Дешевле на`)
                  }} {{
                    Math.abs(calculatedProps.koef * 100 - 100)
                  }}%</span>
                <v-select
                    v-if="!isNewDatetimePicker"
                    style="min-width: 130px"
                    ref="time_select"
                    :rules="[v=>!!v || $store.state._(`Выберите время`)]"
                    autocomplete="off"
                    v-model="options.datetimecleanObj.time"
                    class="time"
                    :items="availableTimes"
                    outlined
                    rounded
                    :placeholder="$store.state._(`Выберите время`)"
                    append-icon="mdi-chevron-down"
                    :no-data-text="$store.state._(`В выбранную дату нет доступного времени`)"
                    @change="calculate">
                  <template v-slot:item="{item}">
                    <div class="d-flex justify-space-between px-4" style="width: 100%">
                      <div>{{ item }}</div>
                      <v-icon color="#FF5834"
                              v-if="koefsDate[item.split(':')[0]] > 0">mdi-arrow-up
                      </v-icon>
                      <v-icon color="#21BEC7"
                              v-if="koefsDate[item.split(':')[0]] < 0">mdi-arrow-down
                      </v-icon>
                    </div>
                  </template>
                </v-select>
              </div>
              <span class="high-demand"
                    v-if="calculatedProps.koef > 1 && calculatedProps.koefObj.titleSmall && $vuetify.breakpoint.width > 600">{{
                  calculatedProps.koefObj.titleSmall
                }}</span>
              <span class="discount"
                    v-if="calculatedProps.koef < 1  && $vuetify.breakpoint.width > 600">{{
                  $store.state._(`Вам повезло, низкий спрос! Дешевле на`)
                }} {{
                  Math.abs(calculatedProps.koef * 100 - 100)
                }}%</span>
              <v-text-field
                  autocomplete="off"
                  v-model="options.promo"
                  outlined
                  rounded
                  :success-messages=" options.promo && calculatedProps.promoInfo ? calculatedProps.promoInfo.title : ''"
                  @change="calculate"
                  :placeholder="$store.state._(`Промокод`)"/>
              <span class="high-demand" v-if="calculatedProps.promoError">{{ calculatedProps.promoError }}</span>
              <v-textarea
                  autocomplete="off"
                  outlined
                  v-model="options.comment"
                  :placeholder="$store.state._(`Комментарий к заказу`)+' ('+ $store.state._(`например, помыть кота или забрать вещи из химчистки`)+')'"
                  rows="3"
                  no-resize
                  style="border-radius: 12px;"
              />
            </v-form>
            <v-btn v-if="calculatedProps.regularCost && calculatedProps.regularCost.length > 0" class="v-btn-main"
                   @click="nextStep">{{ $store.state._(`Далее`) }}
            </v-btn>
            <v-btn v-else class="v-btn-main" @click="create" :loading="loading_create">
              {{ $store.state._(`Заказать`) }}
            </v-btn>
            <p class="privacy__text"><span>{{ $store.state._(`Нажимая кнопку, вы принимаете`) }}</span> <a
                :href="url + 'privacy'"
                target="_blank">{{ $store.state._(`Условия пользовательского соглашения`) }}</a>
              <span> {{ $store.state._(`и даёте согласие`) }}</span> <a :href="url + 'agreement'"
                                                                        target="_blank">{{
                  $store.state._(`на Обработку персональных данных`)
                }}</a>
            </p>
          </div>
        </div>
        <div class="side-wrapper regular-wrapper" v-if="options.type === 'standart' && order_step === 1">
          <div class="back" @click="order_step = 0; calculate()">
            <v-icon size="16">mdi-arrow-left</v-icon>
            {{ $store.state._(`Назад`) }}
          </div>
          <div class="regular-wrapper__title">{{ $store.state._(`Регулярность уборки`) }}</div>
          <div class="regular">
            <p class="regular__desc">
              {{ $store.state._(`Если вы планируете повторять заказ, лучше выбрать регулярную уборку`) }} –
              {{ $store.state._(`это дешевле.`) }}</p>
            <div class="regular-types">
              <div class="regular-type" v-for="(type, index) in regular_types" :key="index">
                <div class="regular-type-text">
                  <div class="regular-type-text__title">{{ type.title }}</div>
                  <div class="regular-type-text__desc">{{ type.text }} {{ type.regularN ? `-${type.regularN}%` : '0%' }}
                  </div>
                </div>
                <v-btn class="v-btn-black" v-if="options.regular === type.value">
                  <v-icon style="color: #ffffff">mdi-check</v-icon>
                </v-btn>
                <v-btn v-else class="v-btn-gray" @click="options.regular = type.value;">
                  {{ $store.state._(`Выбрать`) }}
                </v-btn>
              </div>
            </div>
          </div>
          <v-btn class="v-btn-main" @click="create" :loading="loading_create">{{ $store.state._(`Заказать`) }}</v-btn>
        </div>
      </div>
      <div class="order-total-mobile">
        <div class="order-desc">
          <span class="type">{{ cleantype_options[options.type].title }}, </span>
          <span class="services"
                v-if="additional_services.filter(serv=>parseInt(options[serv.form_name]) > parseInt(serv.form_value)).length"
          >+{{
              additional_services.filter(serv => parseInt(options[serv.form_name]) > parseInt(serv.form_value)).length
            }} {{ $store.state._(`услуг`) }}{{
              additional_services.filter(serv => parseInt(options[serv.form_name]) > parseInt(serv.form_value)).length === 1 ? 'а' :
                  additional_services.filter(serv => parseInt(options[serv.form_name]) > parseInt(serv.form_value)).length > 4 ? '' : 'и'
            }}, </span>
          <span class="discount" v-if="calculatedProps.koef < 1">{{ $store.state._(`Скидка`) }} -{{
              Math.abs(calculatedProps.koef * 100 - 100)
            }} %</span>
          <span class="high-demand"
                v-if="calculatedProps.koef > 1 && calculatedProps.koefObj.titleSmall">{{
              calculatedProps.koefObj.titleSmall
            }}</span>
          <span v-if="calculatedProps.koef === 1" class="date">
          {{
              options.datetimecleanObj.date ? new Date(options.datetimecleanObj.date).toLocaleDateString('ru-Ru', {
                month: 'short', day: 'numeric'
              }) : ''
            }}
          {{ options.datetimecleanObj.date ? options.datetimecleanObj.time : '' }}
        </span>
        </div>
        <div class="total">
          <div class="total-sum-wrapper__sum-mobile">
            <span class="d-block">{{
                sumSeparator(calculatedProps.totalPay)
              }} {{ countrySettings.currency }}</span>
            <span class="d-block old-sum"
                  v-if="parseInt(calculatedProps.totalCost) > parseInt(calculatedProps.totalPay)"
            >{{ sumSeparator(calculatedProps.totalCost) }} {{ countrySettings.currency }}</span>
          </div>
        </div>
      </div>
    </div>
    <TimeDisabledModal ref="TimeDisabledModal" @close="onTimeDisabledModalClose"/>
  </div>
</template>

<script>
import {Server} from "@/server";
import Popup from "@/components/Popup";
import PopupRef from "./PopupRef";
import TimeDisabledModal from "@/components/TimeDisabledModal.vue";
import AdditionalService from "@/components/calc_components/AdditionalService";
import globalMixins from "@/globalMixins";

const server = new Server()
export default {
  name: 'Calculator',
  components: {AdditionalService, TimeDisabledModal, PopupRef, Popup},
  mixins: [globalMixins],
  data() {
    let isCottage = window.path ? window.path.indexOf('/cottage') === 0 : false;
    let getParams = new URLSearchParams(location.search);
    let router_type = getParams.get('type') || 'standart';
    let router_rooms = getParams.get('rooms') || (this.isCottage ? '5' : '1');
    let router_promo = getParams.get('promo') || getParams.get('p') || '';
    let phone = getParams.get('phone') || getParams.get('f') || '';
    // let queryParams = location.search ? location.search.split('&') ? location.search.split('&') : location.search : null;

    // let router_type = queryParams && queryParams.find(el => el.includes('type')) ? queryParams.find(el => el.includes('type')).split('=')[1] : 'standart';

    // let router_rooms = queryParams ? queryParams.find(el => el.includes('rooms')) ? queryParams.find(el => el.includes('rooms')).split('=')[1] : '1' : '1'
    // let router_promo = queryParams ? queryParams.find(el => el.includes('promo')) ? queryParams.find(el => el.includes('promo')).split('=')[1] : '' : ''
    // if (!router_promo) router_promo = queryParams ? queryParams.find(el => el.includes('p')) ? queryParams.find(el => el.includes('p')).split('=')[1] : '' : ''
    if (!router_promo && window.promo) router_promo = window.promo;
    // console.log({router_type, router_rooms, router_promo, queryParams});
    let types = {
      standart: {
        title: this.$store.state._(`Регулярная`),
        tip: this.$store.state._(`Поддержание уюта и чистоты в вашем доме`)
      },
      general: {
        title: this.$store.state._(`Генеральная`),
        tip: this.$store.state._(`Подходит, если давно не убирались, была вечеринка или сменились жильцы`)
      },
      remont: {
        title: this.$store.state._(`После ремонта`),
        tip: this.$store.state._(`Поставим финальную точку в ремонте — очистим квартиру от строительной пыли и пятен`)
      },
    };
    let typesNames = Object.keys(types);
    let type = types[router_type] ? router_type : 'standart';
    return {
      menu_date: false,
      loading_create: false,
      showedAll: !this.isCottage,
      order_step: 0,
      reqNum: 0,
      datepicker: false,
      room_options: {
        1: '1',
        2: '2',
        3: '3',
        4: '4',
        5: '5+',
      },
      cleantype_options: types,
      regular_types: [
        {
          title: this.$store.state._(`Разовая уборка`),
          text: this.$store.state._(`Скидка на следующую уборку`),
          value: ''
        },
        {
          title: this.$store.state._(`Раз в месяц`),
          text: this.$store.state._(`Скидка на следующую уборку`),
          value: '2'
        },
        {
          title: this.$store.state._(`Раз в 2 недели`),
          text: this.$store.state._(`Скидка на следующую уборку`),
          value: '3'
        },
        {
          title: this.$store.state._(`Раз в неделю`),
          text: this.$store.state._(`Скидка на следующую уборку`),
          value: '4'
        },
      ],
      additional_services: [],
      dry_cleaning_services: [],
      options: {
        address: {flattype: router_rooms, wcNum: 1},
        datetimecleanObj: {time: '', date: ''},
        client: {phone},
        type: type,
        regular: '',
        promo: router_promo,
        him: 0,
        services: {} //{count, him_koja, name}
      },
      user: {},
      flatSize: {},
      times: [],
      popupOpened: false,
      koefs: {},
      koefsDate: {},
      orderInfo: {},
      localStorageKey: 'form' + (this.isCottage ? '_cottage' : ''),
      calculatedProps: {},
      worktime: {},
      countrySettings: {},
      slideNum: typesNames.indexOf(type),
      url: null,
      datetimeProps: {
        dateSlots: [''],
        timeSlots: []
      },
      timeError: '',
      timeDisabledModalOpened: false,
      calculate_date_loading: false,
      calculate_loading: false,
      selected_dry_service: '',
      dryCleaningServicesEnabled: 0,
    }
  },
  methods: {
    applyCleanType(typeNum) {
      this.order_step = 0
      this.options.type = Object.keys(this.cleantype_options)[typeNum];
      let today = new Date()
      this.calculate()
    },
    chooseTime(slot) {
      if (slot.disabled) {
        this.$refs.TimeDisabledModal.open();
        this.timeDisabledModalOpened = true;
      } else {
        this.options.datetimecleanObj.time = slot.time;
        this.calculate();
      }
    },
    getServices(getFromStorage = true) {
      server.request('order/services', {all: 1}, (data) => {
        this.dryCleaningServicesEnabled = +data.dry_enabled;
        this.additional_services = Object.entries(data.response).filter(([key, obj]) => {
          if (!isNaN(Number(key))) return obj;
        }).map(([key, obj]) => obj);
        if (this.dryCleaningServicesEnabled) {
          this.dry_cleaning_services = Object.entries(data.response).filter(([key, obj]) => {
            if (isNaN(Number(key))) return obj;
          }).map(([key, obj]) => obj);
        }

        this.url = data.url
        if (data.rooms) this.room_options = data.rooms;
        if (data.flatSize) this.flatSize = data.flatSize;
        this.additional_services.forEach(el => {
          this.options[el.form_name] = parseInt(el.form_value)
        })
        if (getFromStorage && localStorage.getItem(this.localStorageKey)) {
          let params = JSON.parse(localStorage.getItem(this.localStorageKey));
          for (let i in params) {
            if (['promo', 'datetimecleanObj', 'address', 'type'].includes(i)) continue;
            this.options[i] = params[i];
          }
          // this.options.promo = '';
          // this.options.datetimecleanObj = {time: '', date: ''};
          this.slideNum = Object.keys(this.cleantype_options).indexOf(this.options.type);
        }
        setTimeout(() => this.calculate(), 500);
      })
    },
    timeToMinutes(str) {
      let parts = str.split(':')
      return parseInt(parts[0]) * 60 + (parseInt(parts[1]) || 0);
    },
    showTypesModal() {
      window.showModal(document.querySelector('.modal_types_cleaning'));
    },
    calculate() {
      this.reqNum++;
      let reqNum = this.reqNum;
      this.calculate_loading = true;
      server.request('order/calculate', this.prepareParams(), (data) => {
        this.calculate_date_loading = false;
        this.calculate_loading = false;
        if (this.reqNum !== reqNum) return;
        this.datetimeProps.dateSlots = data.date_slots || [];
        this.datetimeProps.timeSlots = data.time_slots || [];
        this.calculatedProps = data.response
        if (!this.options.datetimecleanObj.date && this.datetimeProps.dateSlots.length) {
          this.options.datetimecleanObj.date = this.datetimeProps.dateSlots[1].date;
          this.calculate();
        }
        if (data.user) {
          this.user = data.user;
          this.options.client.phone = data.user.phone;
          if (data.user.name) this.options.client.name = data.user.name;
        }
        this.countrySettings = data.country
        let worktime_from = this.timeToMinutes(data.city.worktime_from)

        let worktime_to = this.timeToMinutes(data.city.worktime_to)

        for (let i = worktime_from; i <= worktime_to; i = i + 30) {
          let hour = Math.floor(i / 60) < 2 ? '0' + Math.floor(i / 60) : Math.floor(i / 60);
          let minutes = i % 60 < 2 ? '0' + i % 60 : i % 60;
          this.times.push((hour === 24 ? '00' : hour) + ':' + minutes)
        }
        for (let i = 1; i <= this.regular_types.length - 1; ++i) this.regular_types[i].regularN = data.response.price['regular' + (i + 1)];
        this.$forceUpdate();
      }, () => {
        this.calculate_date_loading = false;
        this.calculate_loading = false;
      })
    },
    prepareParams() {
      let params = {...this.options}
      params.address.wcNum = params['address.wcNum'] || 1
      params.gid = '';
      try {
        params.cookies = window.document.cookie;
        params.cookies += `isNewDatetimePicker=${this.isNewDatetimePicker};`;
        params.gid = window.ga.getAll()[0].get('clientId');
      } catch (e) {
        console.warn(e)
      }
      params.yid = '';
      for (let i in window) if ((i + '').substr(0, 'yaCounter'.length) === 'yaCounter') {
        params.yid = window[i].getClientID();
      }
      if (this.$store.state.localhost) params.isNewDatetimePicker = 1;
      params.services = this.prepareServices(this.options.services);
      return params
    },
    nextStep() {
      /*if (this.isNewDatetimePicker && this.options.datetimecleanObj.time === '') {
        return;
      }*/
      if (this.$refs.form_orderInfo.validate()) {
        this.order_step = 1;
      } else {
        setTimeout(() => {
          this.$refs.calculator.querySelector('.v-input.error--text input').scrollTo({behavior: 'smooth'});
          this.$refs.calculator.querySelector('.v-input.error--text input').click();
        }, 1)
      }
    },
    create() {
      if (this.loading_create) return;
      if (this.order_step === 0 && this.$refs.form_orderInfo.validate() || this.order_step === 1) {
        this.loading_create = true
        server.request('order/create', this.prepareParams(), (data) => {
          localStorage.setItem(this.localStorageKey, '');
          this.loading_create = false
          if (typeof window.VK === 'object') window.VK.Goal('conversion', {value: data.response.total})
          if (typeof window.trackYm === 'function') window.trackYm('Calc_Order')
          if (typeof window.fbq === 'function') window.fbq('track', 'Purchase', {
            value: data.response.total,
            currency: 'RUB'
          });
          try {
            if (typeof window.dataLayer === 'object') window.dataLayer.push({'event': 'sendform'});
          } catch (e) {
            console.log(e);
          }
          location.href = this.url + 'order';
        }, (data) => {
          alert(data.error || this.$store.state._(`Не получилось оформить заказ`))
          this.loading_create = false
        })
      }
    },
    onDateChange() {
      this.calculate_date_loading = true;
      if (this.isNewDatetimePicker) this.options.datetimecleanObj.time = '';
      else this.menu_date = false;
      // setTimeout(() => {
      //   this.$refs.time_select.$el.querySelector('.v-input__slot').click();
      // }, 20)
    },
    selectDateMobile(date) {
      this.options.datetimecleanObj.date = date;
      this.options.datetimecleanObj.time = '';
      this.calculate_date_loading = true;
      this.calculate();
    },
    onPhoneFocus() {
      if (this.$store.state.phone_placeholder[1] === '7' && !this.options.client.phone.length) {
        this.options.client.phone = '+7 (';
      }
    },
    clearForm() {
      let tempForm = {...this.options};
      this.options = {
        client: {phone: ''},
        type: tempForm.type,
        address: {flattype: tempForm.address.flattype, wcNum: 1},
        datetimecleanObj: {time: '14:00'},
        services: {},
        him: 0,
      };
      this.getServices(false);
    },
    onTimeDisabledModalClose() {
      this.timeDisabledModalOpened = false;
    },
    onDryServiceSelected(service_key) {
      this.selected_dry_service = service_key;
    },
    countSelectedDryServices() {
      let dry_service_count = 0;
      this.dry_cleaning_services.forEach((dry_cleaning_service) => {
        if (dry_cleaning_service && dry_cleaning_service.items) {
          let dry_service_items_count = 0;
          dry_cleaning_service.items.forEach(item => {
            if (+this.options.services[item.form_name]) {
              dry_service_items_count++;
            }
          });
          if (dry_service_items_count) dry_service_count++;
        }
      })
      return dry_service_count;
    }
  },
  computed: {
    dry_service() {
      let minPriceObj = this.dry_cleaning_services.length ? this.dry_cleaning_services[0] : {};
      for (let i in this.dry_cleaning_services) {
        if (+this.dry_cleaning_services[i].price_from < +minPriceObj.price_from) {
          minPriceObj = this.dry_cleaning_services[i];
        }
      }
      return {
        title: 'Химчистка',
        form_name: 'him',
        price_from: minPriceObj.price_from || 0,
        form_type: 'checkbox',
        items: minPriceObj.items || [],
      }
    },

    // dateValue() {
    //   return isNewDatetimePicker ? options.datetimecleanObj.date && options.datetimecleanObj.time : options.datetimecleanObj.date ? `${new Date(options.datetimecleanObj.date).toLocaleDateString($store.state.local, {
    //     month: 'short', day: 'numeric', weekday: 'short' })}${isNewDatetimePicker ? `, ${$store.state._('в')} ${options.datetimecleanObj.time}` : ''}` : ''
    // },
    availableTimes() {
      let now = new Date();
      let nowDate = now.toISOString().split('T');
      if (this.options.datetimecleanObj.date && Date.parse(this.options.datetimecleanObj.date) === Date.parse(nowDate[0])) {
        let curTime = nowDate[1].slice(0, 2);
        return this.times.filter(time => time.split(':')[0] * 1 > (curTime * 1) + 2);
      }
      return this.times;
    },
    isNewDatetimePicker() {
      return this.datetimeProps.dateSlots && this.datetimeProps.dateSlots.length;
    },
    timeSlots() {
      if (!this.datetimeProps.timeSlots) return [];
      return [...this.datetimeProps.timeSlots[0], ...this.datetimeProps.timeSlots[1]];
    },
    isCottage() {
      return window.path && window.path.indexOf('/cottage') === 0;
    }
  },
  watch: {
    options: {
      deep: true,
      handler() {
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.options));
      }
    },
    menu_date(newVal) {
      if (newVal && this.$vuetify.breakpoint.width < 768 && this.isNewDatetimePicker) {
        document.querySelector('body').classList.add('date-picker-opened');
      }
      if (!newVal) document.querySelector('body').classList.remove('date-picker-opened');
    }
  },
  mounted() {
    let openPopup = true;
    this.$eventBus.$on('friendPromo', (data) => {
      this.options.client.phone = data.phone
      this.calculate();
    });
    this.$eventBus.$on('getPromo', (data) => {
      this.options.client.phone = data.phone
      this.options.promo = 'firstpop'
      this.calculate();
    })
    if (this.options.promo && +this.options.promo && this.options.promo.length === 11) {
      this.$refs.PopupRef.opened = true;
    } else if (window.cityCode !== 'pshk' && window.promoFirstpop && openPopup) {
      if (this.$vuetify.breakpoint.width < 600) {
        setTimeout(() => {
          if (!this.options.promo && !this.popupOpened && !this.user.phone) {
            this.$refs.Popup.opened = true;
            this.popupOpened = true;
          }
        }, 45 * 1000);
      } else {
        document.body.onmouseleave = () => {
          if (!this.options.promo && !this.popupOpened && !this.user.phone) {
            this.$refs.Popup.opened = true;
            this.popupOpened = true;
          }
        }
      }
    }
    this.getServices()
    // this.calculate()
  },
}
</script>
<style lang="scss">
.date-picker-opened {
  overflow: hidden;
}

.calculator {
  .value-text-field.theme--light.v-input--is-disabled {
    color: #333333;

    input {
      color: #333333;
    }
  }

  .collapse-btn-wrapper {
    padding: 24px 34px 32px;
  }

  .page-content .flat-options.cottage-options {
    column-gap: 30px;
    margin-bottom: 40px;

    .cleantypes__title, .cleantypes-options {
      margin-left: 0;
      width: 100%;
      max-width: 100%;
    }

    .sqr-wrapper {
      max-width: 168px;
      width: 100%;
      align-items: flex-end;
    }
  }
}

.v-menu__content.datetime-modal-content {
  left: 0 !important;
  width: 100%;
  height: 100vh;
  border-radius: 0;
  max-width: 100%;
  position: fixed;
  top: 0 !important;
}

.time-picker-container {
  width: 100%;
  padding: 12px;

  .time-slot-items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 24px 8px;
    margin-bottom: 33px;

    .time-slot-item-container {
      position: relative;
      cursor: pointer;

      .time {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #C8C6C6;
        border-radius: 8px;
        font-size: 15px;
        line-height: 140%;

        &.selected {
          background: #333333;
          color: #FFFFFF;
        }
      }

      .percent {
        text-align: center;
        position: absolute;
        width: 100%;
        top: 44px;
        font-size: 11px;
        line-height: 120%;
      }
    }
  }

  .time-picker-button {
    justify-content: space-between;
    letter-spacing: normal;

    .v-btn__content {
      font-size: 14px;
    }

    .old-sum {
      margin-left: auto;
      margin-right: 8px;
      color: #B5B0AC;
      text-decoration: line-through;
    }
  }
}

.mobile-datetime-picker {
  background: #FFFFFF;
  width: 100%;
  height: 100%;
  padding: 40px 20px 0;
  display: flex;
  flex-direction: column;
  position: relative;

  &-close.v-btn {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .picker-label {
    margin-bottom: 8px;
    display: block;
    font-size: 18px;
    line-height: 130%;
  }

  .date-slots-container::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .date-slots-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .date-slots-container {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    margin-bottom: 40px;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;

    .date-slot {
      min-width: 46px;
      width: 46px;
      height: 46px;
      border-radius: 50%;
      border: 1px solid #C8C6C6;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 15px;

      &.selected {
        background: #333333;

        span {
          color: #FFFFFF;
        }
      }

      span {
        line-height: 100%;
        height: 15px;
        display: block;
        text-align: center;
      }
    }
  }

  .time-picker-container {
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &-actions {
    position: fixed;
    width: calc(100% - 40px);
    bottom: 140px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    margin-top: auto;
    z-index: 1;
  }
}

.v-date-picker-table__events {
  margin-bottom: -3px !important;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(0, 2%, 90%);
  }
  100% {
    background-color: hsl(200, 14%, 96%);
  }
}

.skeleton-loader {
  animation: skeleton-loading 1s linear infinite alternate;
  border: none !important;
}

@media screen and (max-width: 600px) {
  .calculator {
    .page-content {
      .flat-options.cottage-options {
        .cleantypes__title, .cleantypes-options {
          margin-left: 0;
          width: 100%;
          max-width: 100%;
          margin-bottom: 28px;
        }

        .sqr-wrapper {
          display: grid;
          grid-template-columns: 1fr 1fr;
          max-width: 100%;
          width: 100%;

          .sqr__value {
            max-width: 100%;
          }
        }
      }
    }
  }
}
</style>
