<template>
  <v-app :id="component">
    <link v-if="isLocalhost" href="http://localhost:8888/dist/style/style.min.css"
          rel="stylesheet" type="text/css">
    <Calculator v-if="component==='calculator'"/>
    <Calculator2 v-else-if="component==='calculator2'"/>
    <Feedback v-else-if="component==='feedback'"/>
    <Dry v-else-if="component==='dry'"/>
    <Payment v-else-if="component==='payment'"/>
    <OrderForm v-else-if="component === 'order_form'"/>
  </v-app>
</template>

<script>
import Calculator from "./components/Calculator";
import Feedback from "./components/Feedback";
import Calculator2 from "./components/Calculator2";
import Dry from "@/components/Dry";
import Payment from "@/components/Payment.vue";
import OrderForm from "@/components/OrderForm";

export default {
  name: 'App',
  components: {
    OrderForm,
    Payment,
    Dry,
    Calculator2,
    Feedback,
    Calculator
  },
  data() {
    return {
      component: '',
      isLocalhost: window.location.host.includes('localhost:')
    }
  },
  beforeMount() {
    if (window.smallOrderForm || location.pathname.split('/').indexOf('smallOrderForm') >= 0) {
      this.component = 'order_form';
    } else if (location.pathname.split('/').indexOf('f') >= 0) {
      this.component = 'feedback'
    } else if (location.pathname.split('/').indexOf('calc') >= 0) {
      this.component = 'calculator2'
    } else if (location.pathname.includes('dry')) {
      this.component = 'dry';
    } else if (location.pathname.split('/').indexOf('pay') >= 0) {
      this.component = 'payment';
    } else {
      this.component = 'calculator';
    }
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">
body {
  margin: 0
}

</style>
<style src="vuetify/dist/vuetify.min.css"></style>
<style lang="scss" src="./styles.scss"></style>