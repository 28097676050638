<template>
  <v-dialog
      :width="$vuetify.breakpoint.width < 768 ? 482 : $vuetify.breakpoint.width < 375 ? 315 : 568"
      v-model="opened"
      :retain-focus="false"
      persistent
      @click:outside="close"
      @keyup.esc="close"
  >
    <div class="discount-popup">
      <v-btn @click="close" class="close-btn" icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <div class="discount-title">{{ $store.state._(`На это время все клинеры заняты`) }}🙁</div>
      <div class="discount-text">
        {{ $store.state._(`Но вы можете нам позвонить, мы попробуем найти для вас клинера в ручном режиме`) }}
      </div>
      <v-btn class="v-btn-main get-promo" :href="'tel:'+phone"
      >{{ $store.state._(`Позвонить`) }}
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "TimeDisabledModal",
  emits: ['close'],
  data() {
    return {
      opened: false,
      phone: window.city ? window.city.phone : '',
    }
  },
  methods: {
    close() {
      this.$emit('close');
      this.opened = false;
    },
    open() {
      this.opened = true;
    },
  }
}
</script>

<style scoped lang="scss">
.discount-popup {
  background: url("/dist/images/popup_bg.png") center center no-repeat;
  background-size: cover;
  border-radius: 16px;
  padding: 76px 52px;
  position: relative;
  background-color: white;

  .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1;
  }

  .star {
    position: absolute;
    top: 20px;
    right: 16px;
    font-weight: 500;
    font-size: 24px;
    line-height: 112%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: url("/dist/images/star.svg");
    background-size: cover;
    width: 180px;
    height: 180px;
  }

  .discount-title {
    font-weight: 500;
    font-size: 36px;
    line-height: 120%;
    margin-bottom: 20px;
    max-width: 320px;
  }

  .discount-text {
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 40px;
    }
  }

  .discount-email-input {
    width: 100%;
    margin-bottom: 11px;
  }

  .get-promo {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .discount-popup {
    padding: 72px 44px 64px;

    .star {
      width: 165px;
      height: 165px;
      font-size: 22px;
      top: 16px;
    }

    .discount-title {
      font-size: 32px;
      z-index: 1;
      position: relative;
    }

    .discount-text {
      font-size: 16px;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 30px;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .discount-popup {
    padding: 121px 28px 64px;

    .discount-title {
      font-size: 30px;
    }

    .discount-text {
      font-size: 16px;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 32px;
      }
    }

    .star {
      width: 135px;
      height: 135px;
      font-size: 18px;
    }
  }
}
</style>