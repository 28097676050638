<template>
  <div class="calculator dry">
    <div class="page-content">
      <div class="title-container">
        <h1 class="dry-title">{{$store.state._(`Стоимость химчистки`)}}</h1>
      </div>
      <div class="page-content-wrapper">
        <div class="options-wrapper"
             style="overflow: hidden;"
        >
          <div class="additional-services">
            <div v-if="dry_type!=='all'" class="additional-services--wrapper">
              <div class="service"
                   :style="$vuetify.breakpoint.width < 600 && !showedAll && index > 2 ? 'display: none': ''"
                   :class="parseInt( options.services[service.form_name]) > 0 ? 'selected-service' : ''"
                   v-for="(service, index) in additional_services"
                   :key="index"
              >
                <div class="service-wrapper">
                  <div class="name-and-price-wrapper">
                    <span class="service__name">{{ service.name }}</span>
                    <span class="service__price">{{ service.units_after_price === '%' ? '+' : '' }}{{
                        service.price
                      }} {{
                        service.units_after_price
                      }} {{ service.units_after_price === '%' ? $store.state._(`к стоимости`) : '' }} {{
                        service.step || service.units ? '' : ''
                      }} {{ service.step ? service.step : '' }} {{
                        service.units
                      }}</span>
                  </div>
                  <div>
                    <div>
                      <div class="service-value-stepper" v-if="service.form_type === 'text'">
                        <v-btn class="btn-minus v-btn-gray pa-0"
                               :style="options.services[service.form_name] > 0 ? 'background-color: #9BF0F5 !important' : ''"
                               :disabled="!options.services[service.form_name] || options.services[service.form_name] === (parseInt(service.form_value) || 0)"
                               @click="minusval(service.form_name); calculate()"
                        >-
                        </v-btn>
                        <div class="value">
                          <v-text-field
                              autocomplete="off"
                              type="number"
                              class="value-text-field"
                              v-model="options.services[service.form_name]"
                              @change="calculate()"
                          />
                        </div>
                        <v-btn class="btn-plus v-btn-gray pa-0"
                               :style="options.services[service.form_name] > 0 ? 'background-color: #9BF0F5 !important' : ''"
                               @click="plusval(service.form_name); calculate()"
                        >+
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="leather-option-wrapper" v-if="+options.services[service.form_name]">
                  <div class="name-and-price-wrapper">
                    <span class="service__name">{{$store.state._(`Изделие из кожи`)}}</span>
                    <span class="service__price">+30 %</span>
                  </div>
                  <div class="leather-checkbox">
                    <v-btn
                        @click="options.services[service.form_name+'_him_koja'] = !options.services[service.form_name+'_him_koja']; $forceUpdate(); calculate()"
                        class="btn-plus pa-0"
                        :style="{'background-color':options.services[service.form_name+'_him_koja']?'#333333':'transparent',
                                  'border': `1px solid ${options.services[service.form_name+'_him_koja']?'#333333':'#B5B0AC'}`,
                        }">
                      <v-icon :color="'#fff'" v-if="options.services[service.form_name+'_him_koja']">mdi-check</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="additional-services--wrapper">
              <div class="service section"
                   :style="$vuetify.breakpoint.width < 600 && !showedAll && index > 2 ? 'display: none': ''"
                   :class="dry_section_opened===index || selectedSectionCount(index) ? 'selected-service' : ''"
                   v-for="(section, index) in additional_services"
                   :key="index"
              >
                <div class="service-wrapper">
                  <div class="name-and-price-wrapper">
                    <span class="service__name">
                      {{ section.title }}
                      <span style="color: #B5B0AC;margin-left: 2px"
                            v-if="selectedSectionCount(index)">({{ selectedSectionCount(index) }})</span>
                    </span>
                    <span class="service__price">{{ $store.state._(`от`)}} {{ section.items[0].units_after_price === '%' ? '+' : '' }}{{
                        section.price_from
                      }} {{
                        section.items[0].units_after_price
                      }} {{ section.items[0].units_after_price === '%' ? $store.state._(`к стоимости`) : '' }}
                      {{
                        section.units
                      }}</span>
                  </div>
                  <div>
                    <div>
                      <v-btn v-if="dry_section_opened!==index"
                             @click="dry_section_opened=index"
                             class="btn-add">{{ $store.state._(selectedSectionCount(index) ? 'Изменить' : `Добавить`) }}
                      </v-btn>
                      <v-btn v-else
                             @click="dry_section_opened=''"
                             class="btn-remove">{{ $store.state._(`Скрыть`) }}
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div class="service"
                     style="padding: 0"
                     :style="$vuetify.breakpoint.width < 600 && !showedAll && index > 2 ? 'display: none': ''"
                     :class="parseInt( options.services[service.form_name]) > 0 ? 'selected-service' : ''"
                     v-for="(service, index) in section.items.filter(i=>dry_section_opened===index)"
                     :key="index"
                >
                  <div class="service-wrapper">
                    <div class="name-and-price-wrapper">
                      <span class="service__name">{{ service.name }}</span>
                      <span class="service__price">{{ service.units_after_price === '%' ? '+' : '' }}{{
                          service.price
                        }} {{
                          service.units_after_price
                        }} {{ service.units_after_price === '%' ? $store.state._(`к стоимости`) : '' }} {{
                          service.step || service.units ? '' : ''
                        }} {{ service.step ? service.step : '' }} {{
                          service.units
                        }}</span>
                    </div>
                    <div>
                      <div>
                        <div class="service-value-stepper" v-if="service.form_type === 'text'">
                          <v-btn class="btn-minus v-btn-gray pa-0"
                                 :style="options.services[service.form_name] > 0 ? 'background-color: #9BF0F5 !important' : ''"
                                 :disabled="!options.services[service.form_name] || options.services[service.form_name] === (parseInt(service.form_value) || 0)"
                                 @click="minusval(service.form_name); calculate()"
                          >-
                          </v-btn>
                          <div class="value">
                            <v-text-field
                                autocomplete="off"
                                type="number"
                                class="value-text-field"
                                v-model="options.services[service.form_name]"
                                @change="calculate()"
                            />
                          </div>
                          <v-btn class="btn-plus v-btn-gray pa-0"
                                 :style="options.services[service.form_name] > 0 ? 'background-color: #9BF0F5 !important' : ''"
                                 @click="plusval(service.form_name); calculate()"
                          >+
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="leather-option-wrapper" v-if="+options.services[service.form_name]">
                    <div class="name-and-price-wrapper">
                      <span class="service__name">{{$store.state._(`Изделие из кожи`)}}</span>
                      <span class="service__price">+30 %</span>
                    </div>
                    <div class="leather-checkbox">
                      <v-btn
                          @click="options.services[service.form_name+'_him_koja'] = !options.services[service.form_name+'_him_koja']; $forceUpdate(); calculate()"
                          class="btn-plus pa-0"
                          :style="{'background-color':options.services[service.form_name+'_him_koja']?'#333333':'transparent',
                                  'border': `1px solid ${options.services[service.form_name+'_him_koja']?'#333333':'#B5B0AC'}`,
                        }">
                        <v-icon :color="'#fff'" v-if="options.services[service.form_name+'_him_koja']">mdi-check
                        </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="side-wrapper total-wrapper">
          <div class="total-container">
            <div class="total">
              <div class="discount-wrapper"
                   v-if="parseInt(calculatedProps.totalCost) > parseInt(calculatedProps.totalPay) && calculatedProps.promoInfo">
                <span>{{ calculatedProps.promoInfo.title }}</span>
              </div>
              <div class="total-sum-wrapper">
                <div style="margin-top: 15px;" class="total-sum total-sum-wrapper__title align-self-start">
                  {{ $store.state._(`Итого`) }}:
                </div>
                <div class="total-sum-wrapper__sum">
                  <span class="d-block">{{ calculatedProps.totalPay }} {{ countrySettings.currency }}</span>
                  <span class="old-sum d-block"
                        v-if="parseInt(calculatedProps.totalCost) > parseInt(calculatedProps.totalPay)"
                  >{{ calculatedProps.totalCost }} {{ countrySettings.currency }}</span>
                </div>
              </div>
              <div class="duration-wrapper" v-if="calculatedProps.anotherCurrency" style="justify-content: end;">
                {{ calculatedProps.anotherCurrency }}
              </div>
              <div class="duration-wrapper" v-if="calculatedProps.bonus">
                {{ $store.state._(`Будет списано 100 бонусов`).split('100').join(calculatedProps.bonus) }}
              </div>
              <div class="selected-services">
                <div v-if="options.delivery" class="selected-service">
                  <div class="name">Доставка оборудования</div>
                  <div class="price" style="margin-right: 36px;">
                    {{ price.delivery }}{{ countrySettings.currency }}
                  </div>
                </div>
                <div class="selected-service"
                     v-for="(service, index) in (dry_type!=='all'? additional_services:all_services).filter(serv=>parseInt(options.services[serv.form_name]) > parseInt(serv.form_value))"
                     :key="index"
                >
                  <div class="name">{{ service.name }}</div>
                  <div class="price" v-if="service.units_after_price === '%'">
                    {{ calculatedProps.additionServices[service.form_name] }}{{ countrySettings.currency }}
                  </div>
                  <div class="price" v-else>{{
                      Number(service.price) * options.services[service.form_name] * (options.services[service.form_name + '_him_koja'] ? (1 + price.him_koja / 100) : 1)
                    }}{{
                      service.units_after_price
                    }}
                  </div>
                  <v-btn class="v-btn-delete-service" icon
                         @click="options.services[service.form_name] = 0; $forceUpdate(); calculate()">
                    <v-icon size="16" color="#333">mdi-close</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <div class="order-info">
              <v-form ref="form_orderInfo">
                <v-text-field
                    :rules="[v=>!!v || $store.state._(`Укажите телефон`),
                v=>v && v.length >= 18 || $store.state._(`Укажите телефон`),
                 ]"
                    autocomplete="off"
                    class="phone"
                    @change="calculate"
                    v-model="options.client.phone"
                    outlined
                    rounded
                    @input="options.client.phone = $store.state.checkPhone(options.client.phone)"
                    v-mask="$store.state.setMask(options.client.phone)"
                    :placeholder="$store.state.phone_placeholder"
                />
                <v-text-field
                    v-if="0"
                    autocomplete="off"
                    v-model="options.promo"
                    outlined
                    rounded
                    @change="calculate"
                    :placeholder="$store.state._(`Промокод`)"/>
                <span class="high-demand" v-if="calculatedProps.promoError">{{ calculatedProps.promoError }}</span>
              </v-form>
              <v-btn class="v-btn-main" @click="create" :loading="loading_create">
                {{ $store.state._(`Заказать`) }}
              </v-btn>
            </div>
          </div>
          <div class="additional-options">
            <v-row v-if="!ABTest_dry_delivery && !withoutDelivery" style="align-items: center;margin-bottom: 20px"
                   class="additional-option d-flex justify-lg-space-between">
              <div class="additional-option__label flex-grow-1">{{$store.state._(`Доставка оборудования`)}}</div>
              <v-btn x-small class="v-btn-main" disabled :color="'#D6D0CD'" width="80" height="32">
                +{{ price.delivery }} {{ countrySettings.currency }}
              </v-btn>
            </v-row>
            <div class="additional-options__text mb-6">{{$store.state._(`При подтверждении заказа можно добавить дополнительные опции`)}}:
            </div>
            <v-row style="align-items: center;" class="additional-option d-flex justify-lg-space-between mb-2">
              <div class="additional-option__label flex-grow-1">{{$store.state._(`Удаление запахов`)}}</div>
              <v-btn x-small class="v-btn-main" disabled :color="'#D6D0CD'" width="80" height="32">+{{
                  price.him_smell
                }}%
              </v-btn>
            </v-row>
            <v-row style="align-items: center;" class="additional-option d-flex justify-lg-space-between">
              <div class="additional-option__label flex-grow-1">{{$store.state._(`Убрать шерсть животных`)}}</div>
              <v-btn x-small class="v-btn-main" disabled :color="'#D6D0CD'" width="80" height="32">
                +{{ price.him_jivotnie }}%
              </v-btn>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Server} from "@/server";
import globalMixins from "@/globalMixins";

const server = new Server()
export default {
  name: 'Dry',
  mixins: [globalMixins],
  data() {
    let getParams = new URLSearchParams(location.search);
    let router_rooms = getParams.get('rooms') || '1';
    let router_promo = getParams.get('promo') || getParams.get('p') || '';
    let phone = getParams.get('phone') || getParams.get('f') || '';
    if (!router_promo && window.promo) router_promo = window.promo;
    return {
      loading_create: false,
      showedAll: true,
      ABTest_dry_delivery: window.ABTest_dry_delivery || 0,
      withoutDelivery: window.withoutDelivery || 0,
      reqNum: 0,
      dry_type: window.dry_type || 'all',
      additional_services: [],
      all_services: [],
      dry_section_opened: '',
      options: {
        services: {},
        address: {flattype: router_rooms, wcNum: 1},
        datetimecleanObj: {time: '14:00'},
        client: {phone},
        type: 'other',
        himchistka: 1,
        delivery: 0,
        regular: '',
        promo: router_promo,
      },
      user: {},
      popupOpened: false,
      calculatedProps: {},
      countrySettings: {},
      price: {},
      url: null,
    }
  },
  methods: {
    getServices() {
      server.request('order/services', {dry: this.dry_type}, (data) => {
        if (Array.isArray(data.response)) {
          this.additional_services = data.response
          data.response.forEach(el => {
            this.options.services[el.form_name] = parseInt(el.form_value) || 0;
          })
        } else {
          this.all_services = [];
          for (let i in data.response) {
            this.all_services.push(...data.response[i].items);
            data.response[i].items.forEach(el => {
              this.options.services[el.form_name] = parseInt(el.form_value) || 0;
            });
          }
          this.additional_services = data.response;
        }
        this.url = data.url
        this.price = data.price;

      })
    },
    timeToMinutes(str) {
      let parts = str.split(':')
      return parseInt(parts[0]) * 60 + (parseInt(parts[1]) || 0);
    },
    calculate() {
      this.reqNum++;
      let reqNum = this.reqNum;
      server.request('order/calculate', this.prepareParams(), (data) => {
        if (this.reqNum !== reqNum) return;
        this.calculatedProps = data.response
        if (data.user) {
          this.user = data.user;
          this.options.client.phone = data.user.phone;
          if (data.user.name) this.options.client.name = data.user.name;
        }
        this.countrySettings = data.country
        this.$forceUpdate();
      })
    },
    selectedSectionCount(section_index) {
      let count = 0;
      this.additional_services[section_index].items.forEach(item => {
        if (this.options.services[item.form_name]) count++;
      });
      return count;
    },
    prepareParams() {
      let params = {...this.options}
      params.address.wcNum = params['address.wcNum'] || 1
      params.gid = '';
      try {
        params.cookies = window.document.cookie;
        params.gid = window.ga.getAll()[0].get('clientId');
      } catch (e) {
        console.warn(e)
      }
      params.yid = '';
      for (let i in window) if ((i + '').substr(0, 'yaCounter'.length) === 'yaCounter') {
        params.yid = window[i].getClientID();
      }
      params.services = this.prepareServices(this.options.services);
      if (this.ABTest_dry_delivery && !this.withoutDelivery && params.services.length) {
        params.delivery = 1;
      } else {
        params.delivery = 0;
      }
      this.options.delivery = params.delivery;
      return params
    },
    minusval(val) {
      this.options.services[val] = (Number(this.options.services[val]) || 0) - 1
      this.$forceUpdate()
    },
    plusval(val) {
      this.options.services[val] = (Number(this.options.services[val]) || 0) + 1
      this.$forceUpdate()
    },

    create() {
      if (this.loading_create) return;
      if (this.$refs.form_orderInfo.validate()) {
        this.loading_create = true
        server.request('order/create', this.prepareParams(), (data) => {
          this.loading_create = false
          if (typeof window.VK === 'object') window.VK.Goal('conversion', {value: data.response.total})
          if (typeof window.trackYm === 'function') window.trackYm('Calc_Order')
          if (typeof window.fbq === 'function') window.fbq('track', 'Purchase', {
            value: data.response.total,
            currency: 'RUB'
          });
          try {
            if (typeof window.dataLayer === 'object') window.dataLayer.push({'event': 'sendform'});
          } catch (e) {
            console.log(e);
          }
          location.href = this.url + 'order';
        }, (data) => {
          alert(data.error || this.$store.state._(`Не получилось оформить заказ`))
          this.loading_create = false
        })
      }
    }
  },
  mounted() {
    this.getServices()
    this.calculate()
  },
}
</script>
<style lang="scss">
#dry {
  .v-application--wrap {
    min-height: unset;
  }

  .old-sum {
    font-size: 22px;
  }

  .btn-add, .btn-remove {
    width: 100px;
    height: 30px;
    font-size: 14px;
    letter-spacing: normal;
  }

  .calculator .theme--light.v-btn.v-btn--has-bg.v-btn-gray, .calculator .theme--light.v-btn.v-btn--disabled.v-btn--has-bg.v-btn-gray, .discount-popup .theme--light.v-btn.v-btn--has-bg.v-btn-gray, .discount-popup .theme--light.v-btn.v-btn--disabled.v-btn--has-bg.v-btn-gray {
    background-color: #D6D0CD !important;
  }

  .section {
    .service {
      padding: 0;

      &:after {
        display: none;
      }
    }
  }

  .leather-option-wrapper {
    .service__name {
      color: #B5B0AC;
    }

    min-height: 36px;
    align-items: flex-start;
  }
}

@media (max-width: 959px) {
  .calculator.dry .page-content .side-wrapper {
    padding: 0 32px;
  }
}

.calculator.dry {
  .page-content {
    &-wrapper {
      justify-content: space-between;
    }

    .title-container {
      padding-bottom: 52px;

      .dry-title {
        font-size: 38px;
      }
    }

    .side-wrapper .total {
      margin-top: 0;
      margin-bottom: 10px;
    }

    .options-wrapper {
      width: 664px;
      flex-grow: unset;
    }

    .additional-services--wrapper {
      .service {
        display: block;

        .service-wrapper, .leather-option-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          min-height: 56px;
          flex-grow: 1;


          .leather-checkbox {
            width: 100px;
          }

          .name-and-price-wrapper {
            flex-basis: 60%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
    }

    .page-content-wrapper {
      //justify-content: space-between;
    }

    .side-wrapper {
      .additional-options {
        margin-top: 24px;

        &__text {
          font-size: 20px;
          line-height: 140%;
        }

        .additional-option {
          &__label {
            font-size: 16px;
            line-height: 140%;
          }
        }
      }

      .total .selected-services {
        border-bottom: 0;
        border-top: 1px solid #DFDCDA;
        margin-top: 32px;

        .selected-service {
          margin-top: 10px;
          margin-bottom: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 959px) {
  .calculator.dry .page-content {
    padding-top: 0;

    .additional-services--wrapper .service {
      padding: 0 32px;
    }

    .options-wrapper {
      width: 100%;
      background: #FFFFFF;
      margin-bottom: 60px;
    }

    .title-container {
      padding: 30px 32px 40px;
      background: #FFFFFF;

      .dry-title {
        font-size: 32px;
      }
    }

    .side-wrapper {
      max-width: 100%;
      width: 100%;
      display: flex;
      padding: 0 32px;

      .total-container {
        flex-basis: 50%;
      }

      .additional-options {
        margin-top: 0;
        padding-left: 28px;
        flex-basis: 50%;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .calculator.dry .page-content {
    padding-bottom: 64px;
    padding-top: 0;

    .title-container {
      padding: 30px 28px 32px;
      background: #FFFFFF;

      .dry-title {
        font-size: 24px;
      }
    }

    .options-wrapper {
      margin-bottom: 40px;
    }

    .side-wrapper {
      padding: 0 28px;
      margin-top: 0;
      display: block;

      .total {
        margin-bottom: 32px;
      }

      .additional-options {
        margin-top: 52px;
        padding-left: 0;
      }
    }
  }
  .calculator.dry .page-content .additional-services--wrapper {
    padding-bottom: 40px;

    .service {
      &:last-child:after {
        display: block;
      }

      &:after {
        width: calc(100% - 56px);
        margin: 0 auto;
      }

      padding-left: 28px;
      padding-right: 28px;

      .service-wrapper {
        padding: 4px 0;

        .name-and-price-wrapper {
          align-items: flex-start;
          padding-left: 0;
        }
      }
    }
  }
}
</style>