<template>
  <div class="calculator">
    <v-dialog v-model="discountModal" width="500">
      <v-card class="calculator" style="text-align: center">
        <v-card-title class="mb-4" style="font-size: 24px;justify-content: center;">
          Получите скидку 15% <br>на первый заказ
        </v-card-title>
        <v-card-subtitle>
          Распостраняется на все услуги
        </v-card-subtitle>
        <v-card-text>
          <v-btn class="v-btn-main mt-4" @click="showForm = 1;options.promo = 'first';discountModal = false;calculate()"
                 :loading="loading_create">
            Использовать скидку
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="page-content" style="padding-top: 10px;">
      <div class="page-content-wrapper">
        <div class="options-wrapper">
          <div class="flat-options">
            <div class="room-number">
              <div class="room-number__title options__title mb-4">
                Количество комнат
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-1"
                      v-bind="attrs"
                      v-on="on"
                      style="margin-top: -2px;"
                      size="16"
                      color="#333333">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>
1 комн - 0-50 м<sup>2</sup><br>
2 комн - 50-70 м<sup>2</sup><br>
3 комн - 70-100 м<sup>2</sup><br>
4 комн - 100-150 м<sup>2</sup><br>
5 комн - 150-200 м<sup>2</sup><br>
                  </span>
                </v-tooltip>
              </div>
              <div class="room-number-options" style="justify-content: left;">
                <div
                  @click="options.address.flattype = value; calcWindows()"
                  :class="options.address.flattype === value ? 'room-number-options__option--selected' : ''"
                  class="room-number-options__option" v-for="(room_option, value) in room_options" :key="value">
                  <v-icon v-if="options.address.flattype === value" class="mr-1" color="#333333" size="18">mdi-check
                  </v-icon>
                  {{ room_option }}
                </div>
              </div>
            </div>
          </div>
          <div class="checkboxes">
            <v-checkbox
              v-model="windows"
              :color="windows ? '#333333':'#F4F0ED'"
              on-icon="mdi-check"
              off-icon=""
              dark
              style="margin-bottom: 40px;"
              @change="calcWindows"
            >
              <template v-slot:label>
                <div style="margin-bottom: -35px;">
                  Мытьё окон
                  <p style="font-size: 13px;color: #B5B0AC;">Все окна внутри квартиры</p>
                </div>
              </template>
            </v-checkbox>
          </div>


          <div class="additional-services" style="border-top: 1px solid #E4E4E4;padding-top: 20px">
            <div class="options__title mb-6">
              <span style="cursor: pointer;user-select: none;" @click="showedAll=!showedAll">Дополнительные услуги <img
                width="10" style="margin-bottom: 1px;" :style="showedAll ? 'transform: rotateX(180deg)' : ''"
                src="//cleanbros.ru/dist/images/icons/arrow_menu.svg">
              </span>
              <span @click="clearServices"
                    style="float: right;text-decoration-line: underline;color: #B5B0AC;font-size: 15px;cursor: pointer;user-select: none;">Сбросить все</span>
            </div>
            <div class="additional-services--wrapper" v-if="showedAll">
              <div class="service"
                   :class="parseInt( options[service.form_name]) > 0 ? 'selected-service' : ''"
                   v-for="(service, index) in additional_services"
                   :key="index"
              >
                <div class="name-and-price-wrapper">
                  <span class="service__name">{{ service.name }}</span>
                  <span class="service__price">{{ service.units_after_price === '%' ? '+' : '' }}{{
                      options.type == 'remont' && service.priceRemont ? service.priceRemont : service.price
                    }} {{
                      service.units_after_price
                    }} {{ service.units_after_price === '%' ? 'к стоимости' : '' }} {{
                      service.step || service.units ? '/' : ''
                    }} {{ service.step ? service.step : '' }} {{
                      service.units
                    }}</span>
                </div>
                <div>
                  <div class="service-value-stepper" v-if="service.form_type === 'text'">
                    <v-btn class="btn-minus v-btn-gray"
                           :style="options[service.form_name] > 0 ? 'background-color: #9BF0F5 !important' : ''"
                           :disabled="options[service.form_name] === (parseInt(service.form_value) || 0)"
                           @click="minusval(service.form_name); calculate()"
                    >-
                    </v-btn>
                    <div class="value">
                      <v-text-field
                        autocomplete="off"
                        type="number"
                        class="value-text-field"
                        v-model="options[service.form_name]"
                        @change="calculate()"
                      />
                    </div>
                    <v-btn class="btn-plus v-btn-gray"
                           :style="options[service.form_name] > 0 ? 'background-color: #9BF0F5 !important' : ''"
                           @click="plusval(service.form_name); calculate()"
                    >+
                    </v-btn>
                  </div>
                  <v-btn v-else-if="service.form_type === 'checkbox' && options[service.form_name] == 0"
                         @click="options[service.form_name] = 1; $forceUpdate(); calculate()"
                         class="btn-add">Добавить
                  </v-btn>
                  <v-btn v-else-if="service.form_type === 'checkbox' && options[service.form_name] == 1"
                         @click="options[service.form_name] = 0; $forceUpdate(); calculate()"
                         class="btn-remove">Отменить
                  </v-btn>
                </div>
                <span class="service__tip">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-4"
                      v-bind="attrs"
                      v-on="on"
                      size="16"
                      color="#333333">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span class="d-block">{{ service.info }}</span>
                </v-tooltip>
              </span>
              </div>
            </div>
          </div>
        </div>
        <div style="padding: 0 30px;width: 100%;padding-bottom: 60px"
             v-if="!showForm && $vuetify.breakpoint.width < 600">
          <v-btn class="v-btn-main mt-4" @click="showForm=1;toggleBodyClass(true)" :loading="loading_create">
            Оформить заказ
          </v-btn>
        </div>
        <div class="side-wrapper total-wrapper" @click="showForm=0;toggleBodyClass(false)" :class="showForm ? 'active': ''"
             v-if="order_step === 0">
          <div class="dimmer"></div>
          <div class="total" @click.stop="">
            <v-icon v-if="$vuetify.breakpoint.width < 600" style="position: absolute;right: 10px" size="24"
                    @click="showForm=0;toggleBodyClass(false)">mdi-close
            </v-icon>
            <p class="total__desc" style="font-size: 24px">Ваш заказ</p>
            <div class="selected-services">
              <div class="selected-service" v-if="calculatedProps.additionServices.clean*1">
                <div class="name pr-2" style="font-size: 15px">{{ cleantype_options[options.type].title }} уборка
                  {{ options.address.flattype }}-комн. квартиры
                </div>
                <div class="price">{{ calculatedProps.additionServices.clean }}{{ countrySettings.currency }}
                </div>
              </div>
              <div class="selected-service"
                   v-for="(service, index) in additional_services.filter(serv=>parseInt(options[serv.form_name]) > parseInt(serv.form_value))"
                   :key="index"
              >
                <div class="name pr-2" style="font-size: 15px">{{ service.name }}</div>
                <div class="price" v-if="service.form_name === 'address.wcNum'">
                  {{
                    Number(options.type == 'remont' && service.priceRemont ? service.priceRemont : service.price) * options[service.form_name] - Number(options.type == 'remont' && service.priceRemont ? service.priceRemont : service.price)
                  }}{{ service.units_after_price }}
                </div>
                <div class="price" v-else-if="service.units_after_price === '%'">
                  {{ calculatedProps.additionServices[service.form_name] }}{{ countrySettings.currency }}
                </div>
                <div class="price" v-else>{{
                    Number(options.type == 'remont' && service.priceRemont ? service.priceRemont : service.price) * options[service.form_name]
                  }}{{
                    service.units_after_price
                  }}
                </div>
                <v-btn style="margin-top: -5px;" class="v-btn-delete-service" icon
                       @click="options[service.form_name] = 0; $forceUpdate(); calculate()">
                  <v-icon size="16" color="#333">mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
            <div class="discount-wrapper"
                 v-if="parseInt(calculatedProps.totalCost) > parseInt(calculatedProps.totalPay) && calculatedProps.promoInfo">
              <span>{{ calculatedProps.promoInfo.title }}</span>
            </div>
            <div class="total-sum-wrapper">
              <div class="total-sum total-sum-wrapper__title" style="font-size: 24px;color: #333333">Итого</div>
              <div class="total-sum-wrapper__sum">
                <span class="old-sum" style="font-size: 24px"
                      v-if="parseInt(calculatedProps.totalCost) > parseInt(calculatedProps.totalPay)"
                >{{ calculatedProps.totalCost }} {{ countrySettings.currency }}</span>
                <span style="font-size: 24px"> {{
                    calculatedProps.totalPay
                  }} {{ countrySettings.currency }}</span>
              </div>
            </div>
            <div class="duration-wrapper">
              <div>Расчёт предварительный и зависит от даты и времени</div>
            </div>
            <div class="high-demand-wrapper" v-if="calculatedProps.koef > 1 || calculatedProps.koef < 1">
              {{
                calculatedProps.koef > 1 ? calculatedProps.koefObj.title : `Скидка! (-${Math.abs(calculatedProps.koef * 100 - 100)}%)`
              }}

              <span v-if="calculatedProps.koef > 1">{{ calculatedProps.koefObj.subtitle }}</span>
            </div>
            <div class="order-info" v-if="showForm">
              <div style="
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
">Введите данные для заказа
              </div>
              <v-form ref="form_orderInfo">
                <v-text-field
                  :rules="[v=>!!v || 'Укажите имя']"
                  autocomplete="off"
                  v-model="options.client.name"
                  outlined
                  style="border: 1px solid #DFDFDF;"
                  rounded
                  hide-details
                  placeholder="Имя"/>
                <v-text-field
                  :rules="[v=>!!v || 'Укажите телефон',
                v=>v && v.length === 18 || 'Укажите телефон',
                 ]"
                  autocomplete="off"
                  class="phone"
                  type="tel"
                  style="border: 1px solid #DFDFDF;"
                  @change="calculate"
                  hide-details
                  v-mask="$store.state.setMask(options.client.phone)"
                  :placeholder="$store.state.phone_placeholder"
                  v-model="options.client.phone"
                  outlined
                  rounded
                />
                <v-text-field
                  style="display:none;"
                  autocomplete="off"
                  v-model="options.promo"
                  outlined
                  hide-details
                  rounded
                  @change="calculate"
                  placeholder="Промокод"/>
                <span class="high-demand" v-if="calculatedProps.promoError">{{ calculatedProps.promoError }}</span>
              </v-form>
            </div>
            <v-btn class="v-btn-main mt-4" @click="showForm ? create() : showForm=1" :loading="loading_create">
              Оформить заказ
            </v-btn>
          </div>
        </div>
      </div>
      <div class="order-total-mobile" @click="showForm=1;toggleBodyClass(true)">
        <div class="total" style="display:flex;justify-content: space-between;width: 100%">
          <div style="font-size: 20px">Итого</div>
          <div class="total-sum-wrapper__sum-mobile">
            <span class="d-block" style="font-size: 24px">{{ calculatedProps.totalPay }} {{
                countrySettings.currency
              }}</span>
            <span class="d-block old-sum"
                  v-if="parseInt(calculatedProps.totalCost) > parseInt(calculatedProps.totalPay)"
            >{{ calculatedProps.totalCost }} {{ countrySettings.currency }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Server} from "@/server";

const server = new Server()
export default {
  name: 'Calculator2',
  data() {
    let queryParams = location.search ? location.search.split('&') ? location.search.split('&') : location.search : null;

    let router_type = queryParams && queryParams.find(el => el.includes('type')) ? queryParams.find(el => el.includes('type')).split('=')[1] : 'standart';

    let router_rooms = queryParams ? queryParams.find(el => el.includes('rooms')) ? queryParams.find(el => el.includes('rooms')).split('=')[1] : '1' : '1'
    let router_promo = queryParams ? queryParams.find(el => el.includes('promo')) ? queryParams.find(el => el.includes('promo')).split('=')[1] : '' : ''
    if (!router_promo && window.promo) router_promo = window.promo;
    let types = {
      standart: {title: 'Регулярная', tip: 'Поддержание уюта и чистоты в вашем доме'},
      general: {
        title: 'Генеральная',
        tip: 'Подходит, если давно не убирались, была вечеринка или сменились жильцы'
      },
      remont: {
        title: 'После ремонта',
        tip: 'Поставим финальную точку в ремонте — очистим квартиру от строительной пыли и пятен'
      },
    };
    let typesNames = Object.keys(types);
    let type = types[router_type] ? router_type : 'standart';
    return {
      menu_date: false,
      loading_create: false,
      showedAll: false,
      showForm: false,
      order_step: 0,
      reqNum: 0,
      windows: false,
      datepicker: false,
      discountModal: false,
      discountModal2: false,
      room_options: {
        1: '1',
        2: '2',
        3: '3',
        4: '4',
        5: '5',
      },
      cleantype_options: types,
      regular_types: [
        {title: 'Разовая уборка', text: `Стоимость 1 уборки – `, value: ''},
        {title: 'Раз в месяц', text: `Стоимость 1 уборки – `, value: '2'},
        {title: 'Раз в 2 недели', text: `Стоимость 1 уборки – `, value: '3'},
        {title: 'Раз в неделю', text: `Стоимость 1 уборки – `, value: '4'},
      ],
      additional_services: [],
      options: {
        address: {flattype: router_rooms, wcNum: 1},
        datetimecleanObj: {time: '14:00'},
        client: {},
        type: type,
        regular: '',
        promo: router_promo,
      },
      times: [],
      koefs: {},
      koefsDate: {},
      orderInfo: {},
      calculatedProps: {additionServices: {}},
      worktime: {},
      countrySettings: {},
      slideNum: typesNames.indexOf(type),
      url: null,
    }
  },
  methods: {
    applyCleanType(typeNum) {
      this.order_step = 0
      this.options.type = Object.keys(this.cleantype_options)[typeNum];
      let today = new Date()
      this.getKoefsPerMonth(today.toISOString().slice(0, 10))
      this.calculate()
    },
    getServices() {
      server.request('order/services', {}, (data) => {
        this.additional_services = data.response
        this.url = data.url
        this.room_options = data.rooms;
        data.response.forEach(el => {
          this.options[el.form_name] = parseInt(el.form_value)
        })
      })
    },
    getKoefsPerMonth(date) {
      date = date ? date.split('-') : ''
      server.request('order/koef', {type: this.options.type, month: date[1], year: date[0]}, (data) => {
        this.koefs = data.response;
      })
    },
    getKoefsPerDate() {
      server.request('order/koef', {
        type: this.options.type,
        date: this.options.datetimecleanObj.date
      }, (data) => {
        this.koefsDate = data.response;
      })
    },
    timeToMinutes(str) {
      let parts = str.split(':')
      return parseInt(parts[0]) * 60 + (parseInt(parts[1]) || 0);
    },
    calcWindows() {
      this.options.windowNum = this.windows ? (this.options.address.flattype * 1 + 2) * 2 : 0;
      this.calculate();
    },
    calculate() {
      this.reqNum++;
      let reqNum = this.reqNum;
      server.request('order/calculate', this.prepareParams(), (data) => {
        if (this.reqNum !== reqNum) return;
        this.calculatedProps = data.response
        if (data.user) {
          this.options.client.phone = data.user.phone;
          if (data.user.name) this.options.client.name = data.user.name;
        }
        this.countrySettings = data.country
        let worktime_from = this.timeToMinutes(data.city.worktime_from)

        let worktime_to = this.timeToMinutes(data.city.worktime_to)

        for (let i = worktime_from; i <= worktime_to; i = i + 30) {
          let hour = Math.floor(i / 60) < 2 ? '0' + Math.floor(i / 60) : Math.floor(i / 60);
          let minutes = i % 60 < 2 ? '0' + i % 60 : i % 60;
          this.times.push((hour === 24 ? '00' : hour) + ':' + minutes)
        }
        for (let i = 1; i <= this.regular_types.length - 1; ++i) this.regular_types[i].regularN = data.response.price['regular' + (i + 1)];
        this.$forceUpdate();
      })
    },
    prepareParams() {
      let params = {...this.options}
      params.address.wcNum = params['address.wcNum'] || 1
      params.gid = '';
      try {
        params.cookies = window.document.cookie;
        params.gid = window.ga ? window.ga.getAll()[0].get('clientId') : '';
      } catch (e) {
        console.warn(e)
      }
      params.yid = '';
      for (let i in window) if ((i + '').substr(0, 'yaCounter'.length) === 'yaCounter') {
        params.yid = window[i].getClientID();
      }
      return params
    },
    minusval(val) {
      this.options[val] = (Number(this.options[val]) || 0) - 1
      this.$forceUpdate()
    },
    plusval(val) {
      this.options[val] = (Number(this.options[val]) || 0) + 1
      this.$forceUpdate()
    },
    nextStep() {
      if (this.$refs.form_orderInfo.validate()) {
        this.order_step = 1;
      }
    },
    clearServices() {
      this.additional_services.forEach(service => {
        if (this.options[service.form_name] * 1) {
          this.options[service.form_name] = service.form_value;
        }
      });
      this.windows = false;
      this.calculate();
    },
    toggleBodyClass(add) {
      add ? document.body.classList.add('with_dimmer') : document.body.classList.remove('with_dimmer');
    },
    create() {
      if (this.loading_create) return;
      if (this.order_step === 0 && this.$refs.form_orderInfo.validate() || this.order_step === 1) {
        this.loading_create = true
        server.request('order/create', this.prepareParams(), (data) => {
          this.loading_create = false
          if (typeof window.trackYm === 'function') window.trackYm('Calc_Order')
          if (typeof window.VK === 'object') window.VK.Goal('conversion', {value: data.response.total})
          if (typeof window.fbq === 'function') window.fbq('track', 'Purchase', {
            value: data.response.total,
            currency: 'RUB'
          });
          try {
            if (typeof window.dataLayer === 'object') window.dataLayer.push({'event': 'sendform'});
          } catch (e) {
            console.log(e);
          }
          location.href = this.url + 'order';
        }, (data) => {
          alert(data.error || 'Не получилось оформить заказ')
          this.loading_create = false
        })
      }
    }
  },
  mounted() {
    this.getServices()
    this.calculate()
    // if (this.$vuetify.breakpoint.width < 600) {
    //   setTimeout(() => {
    //     this.discountModal = true;
    //   }, 35 * 1000);
    // } else {
    //   document.body.onmouseleave = () => {
    //     if (!this.options.promo && !this.discountModal2) {
    //       this.discountModal = true;
    //       this.discountModal2 = true;
    //     }
    //   }
    // }
  },

}
</script>
<style>
#calculator2 .v-application--wrap {
  background: #FFFFFF;
}

#calculator2 .side-wrapper .total {
  background: #F4F0ED;
}

#calculator2 .calculator .page-content .cleantypes-options .v-window__next, #calculator2 .calculator .page-content .cleantypes-options .v-window__prev {
  border: 0;
}

#calculator2 .calculator .v-btn {
  text-transform: none;
}

#calculator2 .calculator .page-content .additional-services--wrapper .service {
  padding-left: 10px;
  padding-right: 10px;
}

#calculator2 .calculator .page-content .additional-services--wrapper .service:after {
  left: 0;
  right: 0;
}

#calculator2 .calculator .page-content .flat-options .room-number-options__option {
  width: 43px;
  min-width: 0;
  height: 43px;
  border: 1px solid #B5B0AC;
  color: #B5B0AC;
  margin-right: 10px;
  padding-top: 1px;
  padding-left: 1px;
}

#calculator2 .v-input--selection-controls__ripple {
  margin: 7px;
}

#calculator2 .calculator .page-content .additional-services--wrapper .service.selected-service {
  /*background: #FFFFFF;*/
}

#calculator2 .calculator .page-content .total .total-sum-wrapper {
  margin-top: 20px;
}

#calculator2 .calculator .page-content .side-wrapper .total {
  margin-top: 0;
}

#calculator2 .calculator .page-content .side-wrapper {
  max-width: 500px;
}

#calculator2 .v-application--wrap {
  min-height: calc(100vh - 200px);
}

#calculator2 .calculator .page-content .options-wrapper {
  padding-top: 10px;
}

#calculator2 .calculator .theme--light.v-btn.v-btn--has-bg.v-btn-gray, .calculator .theme--light.v-btn.v-btn--disabled.v-btn--has-bg.v-btn-gray {
  background-color: #fff !important;
}

#calculator2 .service-value-stepper .v-btn__content {
  font-size: 30px;
  font-weight: 300;
}

#calculator2 .calculator .page-content .options__title {
  text-transform: none;
  font-size: 20px;
  letter-spacing: normal;
}

#calculator2 .calculator .page-content .flat-options .room-number-options__option--selected {
  width: 63px !important;
  background: #F4F0ED;
  color: #333333;
  border-color: #F4F0ED;
}

#calculator2 .calculator .page-content .options__title {
  height: auto;
}

#calculator2 .checkboxes {
  display: inline-block;
}

@media (max-width: 600px) {
  #calculator2 .cleantypes {
    margin-top: 20px;
  }

  #calculator2 .side-wrapper:not(.active) {
    top: 100vh;
  }

  #calculator2 .side-wrapper.active .dimmer {
    display: block;
  }

  #calculator2 .side-wrapper .dimmer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #0000005e;
    display: none;
    z-index: 1;
  }

  #calculator2 .side-wrapper .total {
    height: fit-content;
    transition: margin-top 1s;
    margin-bottom: 0;
    position: relative;
    z-index: 3;
    border-radius: 12px 12px 0 0;
    background: #fff;
    width: 100vw;
    padding-bottom: 80px;
    max-height: 90vh;
    min-height: 80vh;
    overflow-y: scroll;
  }

  #calculator2 .side-wrapper {
    position: fixed;
    scroll-behavior: unset;
    top: 0;
    overflow-y: scroll;
    transition: top 300ms;
    left: 0;
    height: 100vh;
    display: flex;
    align-items: flex-end;
    z-index: 2;
    padding: 0;
  }

  #calculator2 .checkboxes {
    padding: 0 30px;
  }

  #calculator2 * {
    font-size: 16px;
  }

  #calculator2 .calculator .page-content .additional-services .service .name-and-price-wrapper {
    padding-left: 20px;
  }

  #calculator2 .calculator .page-content .additional-services .service .service__tip {
    padding-right: 20px;
  }
}

body.with_dimmer {
  height: 100vh;
  overflow: hidden;
}

#calculator2 .v-input--selection-controls__input .v-input--selection-controls__ripple:before {
  opacity: 0;
}

#calculator2 .checkboxes .v-input--selection-controls__input .v-icon {
  background: #F4F0ED;
  border-radius: 4px;
}

#calculator2 .checkboxes .v-icon.v-icon {
  font-size: 18px;
}

#calculator2 .checkboxes .v-input--selection-controls__input {
  width: 28px;
  height: 28px;
  margin-right: 20px;
}
#calculator2 .order-total-mobile {
  padding: 30px 20px;
}
#calculator2 .total-sum-wrapper__sum-mobile {
  margin-right: calc(50vw - 20px - 39px);
}
</style>