<template>
  <div class="page-content" style="padding: 10px;">
    <div class="page-content-wrapper">
      <v-card :loading="loading_card" style="max-width: 400px;margin: auto;margin-top: 40px">
        <v-card-title>{{$store.state._(`Оцените уборку`)}}</v-card-title>
        <v-card-subtitle v-if="!error_only">
          {{ order.datetimecleanObj.date }} {{ order.datetimecleanObj.time }}
        </v-card-subtitle>
        <v-card-text>
          <v-alert v-if="error" outlined type="error">{{ error }}</v-alert>
          <v-alert v-if="success" outlined type="success">
            <div>{{$store.state._(`Спасибо за оценку!`)}}</div>
          </v-alert>
          <template v-if="success && yandexMapsBonus">
            <v-card-title class="pa-0 mb-2" style="line-height: 24px">Получите {{yandexMapsBonus}} бонусов за отзыв<br> на Яндекс Картах</v-card-title>
            <p>
              Оставьте отзыв об уборке на Яндекс картах и получите <b>{{yandexMapsBonus}} бонусов</b> на ваш счет
              <br>*для оперативного зачисления бонусов пришлите скрин отзыва в наш мессенджер
            </p>
              <v-btn :href="yandexMapsLink" target="_blank" color="#9BF0F5" style="text-transform: none;width: 100%;border-radius: 40px;height: 50px"
                     :loading="loading_done">{{$store.state._(`Оставить отзыв`)}}
              </v-btn>
          </template>
          <template v-if="!error_only">
            <v-subheader style="font-size: 20px" class="px-0">{{$store.state._(`Работа клинера(ов)`)}}</v-subheader>
            <div style="display: flex;gap: 20px;flex-wrap: wrap" class="mb-2">
              <div style="flex: 1;text-align: center" v-for="(cleaner, i) in order.cleaners" :key="i">
                <v-avatar :size="100">
                  <v-img :src="cleaner.photo"></v-img>
                </v-avatar>
                <div>{{ cleaner.name }}</div>
              </div>
            </div>
            <v-row class="mb-4 row-d-block">
              <v-rating
                  v-model="feedback.rate"
                  hover
                  @input="error = ''"
                  dense
                  color="amber"
                  background-color="amber"
                  size="64"
                  class="rating"
              />
            </v-row>
            <v-row class="mb-4 row-d-block px-3" v-if="feedback.rate && feedback.rate<5">
              <v-subheader>{{$store.state._(`Что не понравилось в работе клинера(ов)?`)}}</v-subheader>
              <v-checkbox
                  style="width: 100%;margin-top:0"
                  v-for="checkbox in order.feedbackCheckboxes.filter(f=>f.n_id<8)"
                  v-model="feedback.checkboxes"
                  :key="checkbox.n_id"
                  :label="checkbox.title"
                  :value="checkbox.n_id"
              />
            </v-row>
            <v-subheader style="font-size: 20px" class="px-0">{{$store.state._(`Работа менеджера`)}}</v-subheader>
            <v-row class="mb-4 row-d-block">
              <v-rating
                  v-model="feedback.rate_manager"
                  hover
                  @input="error = ''"
                  dense
                  color="amber"
                  background-color="amber"
                  size="64"
                  class="rating"
              />
            </v-row>
            <v-row class="mb-4 row-d-block px-3" v-if="feedback.rate_manager && feedback.rate_manager<5">
              <v-subheader>{{$store.state._(`Что не понравилось в работе менеджера?`)}}</v-subheader>
              <v-checkbox
                  style="width: 100%;margin-top:0"
                  v-for="checkbox in order.feedbackCheckboxes.filter(f=>f.n_id>=8)"
                  v-model="feedback.checkboxes"
                  :key="checkbox.n_id"
                  :label="checkbox.title"
                  :value="checkbox.n_id"
              />
            </v-row>
            <v-row class="pa-3">
              <v-textarea
                  :placeholder="$store.state._(`Комментарий`)"
                  v-model="feedback.comment"
                  outlined
                  rows="3"
                  hide-details
              />
            </v-row>
            <v-row class="pa-3 mt-0" v-if="feedback.rate_manager==5 && feedback.rate==5">
              <v-textarea
                  :placeholder="$store.state._(`Расскажите, что для вас было самым ценным при заказе уборки в нашей компании?`)"
                  v-model="feedback.comment2"
                  outlined
                  rows="3"
              />
            </v-row>
          </template>
        </v-card-text>
        <v-card-actions v-if="!error_only" style="padding: 0 20px 20px">
          <v-btn @click="rate" color="#9BF0F5" style="text-transform: none;width: 100%;border-radius: 40px;height: 50px"
                 :loading="loading_done">{{$store.state._(`Готово`)}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import {Server} from "@/server";

const server = new Server()
export default {
  name: 'Feedback',
  data() {
    return {
      order: {datetimecleanObj: {}, cleaners: []},
      params: {},
      feedback: {checkboxes: []},
      loading_done: false,
      loading_card: true,
      yandexMapsBonus: 0,
      yandexMapsLink: '',
      error: '',
      success: false,
      error_only: true,
    };
  },
  methods: {
    loadOrder() {
      server.request('order/feedbackInfo', this.params, (data) => {
        this.order = data.response;
        this.loading_card = false;
        this.error_only = false;
      }, (data) => {
        if (data.error) this.error = data.error;
        else this.error = this.$store.state._(`Не получилось загрузить информацию`);
        this.loading_card = false;
      });
    },
    rate() {
      if (!this.feedback.rate) return this.error = this.$store.state._(`Поставьте, пожалуйста, оценку`);
      this.error = '';
      this.loading_done = true;
      let params = {...this.params};
      params.feedback = {...this.feedback};
      server.request('order/rate', params, (data) => {
        this.success = true;
        this.error_only = true;
        this.yandexMapsBonus = data.yandexMapsBonus;
        this.yandexMapsLink = data.yandexMapsLink;
        this.loading_done = false;
        window.scrollTo(0,0)
      }, () => {
        this.error = this.$store.state._(`Не получилось оценить уборку`)
        this.loading_done = false;
      });
    },
    setUpParams() {
      let parts = location.pathname.split('/');
      let index = parts.indexOf('f');
      this.params = {
        first: parts[index + 1],
        second: parts[index + 2],
      }
    }
  },
  mounted() {
    this.setUpParams();
    // this.success = true;
    // this.error_only = true;
    // this.loading_card = false;
    this.loadOrder();
  },
}
</script>
<style>
.v-application--wrap {
  min-height: unset;
}
* {
  color: #333333;
  padding: 0;
  margin: 0;
  font-family: Gerbera;
  font-weight: 400;
  /*letter-spacing: normal;*/
}
</style>