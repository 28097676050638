export class Server {
    constructor() {
        // let prefix = location.host.indexOf('localhost')!==0 ? 'http://localhost:8888' : 'https://' + location.host;
        let prefix = location.host.indexOf('localhost')===0 ? 'https://cleanbros.ru' : 'https://' + location.host;
        this.url =prefix + (window.href ? window.href('/api/') : '/tst/api/');
    }

    serialize(obj, prefix) {
        var str = [];
        for (var p in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, p)) {
                var k = prefix ? prefix + '[' + p + ']' : p,
                    v = obj[p];
                str.push(typeof v == 'object' ? this.serialize(v, k) : encodeURIComponent(k) + '=' + encodeURIComponent(v));
            }
        }
        return str.join('&');
    }

    request(method, data, onSuccess, onError, onFinally) {
        const requestParams = {
            ...data,
        };
        const query = this.serialize(requestParams);

        const checkForError = (response) => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        };

        return fetch(`${this.url + method}?${query}`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
        })
            .then(checkForError)
            .then((response) => {
                if (!response.success && response.error === 'user is not logged in') {
                    window.location.reload()
                    return;
                }
                if (!response.success && typeof onError === 'function') return onError(response);
                if (typeof onSuccess === 'function') onSuccess(response);
                return response;
            })
            .catch((error) => {
                if (typeof onError === 'function') onError(error);
                console.log(error)
                return error;
            })
            .finally(() => {
                if (typeof onFinally === 'function') onFinally();
            });
    }

}
