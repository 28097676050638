export default {
  methods: {
    sumSeparator(sum) {
      return String(sum)
        .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 '); // 1 000 000 000
    },
    prepareServices(services) {
      let result = [];
      for (let i in services) {
        if (i.indexOf('him_koja') > 0) continue;
        if (+services[i] < 1) continue;
        result.push({
          count: +services[i],
          him_koja: services[i + '_him_koja'] ? 1 : 0,
          name: i,
        });
      }
      return result;
    },
  }
}